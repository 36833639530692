import React, { useState } from 'react';
import {
    CInput,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText
} from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const PasswordInput = (props) => {

    const [visibilityState, setVisibilityState] = useState(false);

    return (

        <CInputGroup className='sm-password'>
            {/* <input type="password" style={{ display: 'none' }} /> */}
            <CInput className={visibilityState ? '' : 'sm-password-input'} type='text' id={props.id} name={props.name} value={props.inputVal} onChange={props.onChange} />
            <CInputGroupAppend>
                <CInputGroupText className='sm-password-icon' onClick={() => { setVisibilityState(!visibilityState) }}><FontAwesomeIcon icon={visibilityState ? faEye : faEyeSlash} className="fa-lg" /></CInputGroupText>
            </CInputGroupAppend>
        </CInputGroup>

    )
};

export default PasswordInput;
