import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../redux/reducers";

const store = () => {

    const middlewares = [];
    middlewares.push(thunk);
    const store = createStore(rootReducer, compose(applyMiddleware(...middlewares)))
    return { store };
}

export default store;