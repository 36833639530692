import React, { useEffect } from 'react';
import {
    CHeaderNav
} from '@coreui/react'
import { Menu } from 'react-feather';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Switch,
    Route,
    useLocation
} from "react-router-dom";
import * as panelActions from '../../../redux/actions/panelActions';
import OutboundDialerSearch from '../../pages/outboundDialer/searchUser/outboundDialerSearch';
import OutboundDialerCSVImport from '../../pages/outboundDialer/csvImport/outboundDialerCSVImport';
import SocialAppManager from '../../pages/socialAppManager/socialAppManager';
import Dashboard from '../../pages/dashboard/dashboard';
import * as selectedPageActions from '../../../redux/actions/selectedPageActions';
import LogoutButton from './logoutButton';


const Wrapper = (props) => {

    let location = useLocation();

    useEffect(() => {

        let paths = location.pathname.substring(1).split('/');

        let navName = paths.length === 3 ? `nav_${paths[1]}` : "";
        let pageName = `nav_${paths.join('_')}`;

        props.selected_page_actions.pageSelected(pageName, navName);

    }, []);

    return (

        <>
            <div className="c-wrapper">
                <header className="c-header c-header-light c-header-fixed">
                    <button className="c-header-toggler c-class-toggler d-lg-none mfe-auto" type="button" data-target="#sidebar" data-class="c-sidebar-show" onClick={() => {
                        props.panel_actions.navigationMobileVisibilityChanged();
                    }}>
                        <Menu size={24} />
                    </button>
                    <a className="c-header-brand d-lg-none c-header-brand-sm-up-center" href="#">
                    </a>
                    <button className="c-header-toggler c-class-toggler mfs-3 d-md-down-none" type="button" data-target="#sidebar" data-class="c-sidebar-lg-show" responsive="true" onClick={() => {
                        props.panel_actions.navigationWebVisibilityChanged();
                    }}>
                        <Menu size={24} />
                    </button>

                    <div className="d-md-down-none mr-auto c-header-nav"></div>
                    <CHeaderNav className="px-3">
                        <LogoutButton />
                    </CHeaderNav>


                </header>
                <div className="c-body">
                    <main className="c-main">
                        <div className="container-fluid">
                            <div className="fade-in">

                                <Switch>
                                    <Route path="/console/outbound-dialer/search-user">
                                        <OutboundDialerSearch />
                                    </Route>
                                    <Route path="/console/outbound-dialer/csv-import">
                                        <OutboundDialerCSVImport />
                                    </Route>
                                    <Route path="/console/social-app-manager">
                                        <SocialAppManager />
                                    </Route>
                                    <Route path="/console/dashboard">
                                        <Dashboard />
                                    </Route>
                                </Switch>

                            </div>
                        </div>
                    </main>
                </div>
            </div >

        </>




    )
};

function mapDispatchToProps(dispatch) {
    return {
        panel_actions: bindActionCreators(panelActions, dispatch),
        selected_page_actions: bindActionCreators(selectedPageActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(Wrapper);
