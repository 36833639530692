import { panelReducer } from "./panelReducer";
import { selectedPageReducer } from "./selectedPageReducer";
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    // Define a top-level state field named `todos`, handled by `todosReducer`
    panel: panelReducer,
    selected_page: selectedPageReducer
})

export default rootReducer
