import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import {
    CCol,
    CRow,
    CFormGroup,
    CLabel,
    CSwitch,
    CSelect,
    CTextarea,
    CButton

} from '@coreui/react';
import uuid from "uuid/v1";
import ContactUploader from './contactUploader';
import SenderAccountsPicker from './senderAccountsPicker';
import { showAlert } from '../../../../util/uiNotifications';
import SenderAccSelector from '../../../subcomponents/outboundDialer/senderAccSelector';
import FileBrowser from './fileBrowser';
import { supportedTypes } from '../../../../config/json/fileTypes';

const DialerCSVInputForm = forwardRef((props, ref) => {

    let smProviders = [];

    props.socialAccounts.forEach(acc => {
        if (acc.SocialMediaApplication && !smProviders.includes(acc.SocialMediaApplication.SocialMediaTypeId)) {
            smProviders.push(acc.SocialMediaApplication.SocialMediaTypeId);
        }
    });

    const [formData, setFormData] = useState({});

    const childRef = useRef();
    const senderAccPickerRef = useRef();
    const fileBrowserRef = useRef();

    const onFormInputChanged = (name, value) => {
        setFormData(prevVal => { return { ...prevVal, [name]: value } })
    }

    useImperativeHandle(ref, () => ({

        resetForm() {
            setFormData({});
            fileBrowserRef.current && fileBrowserRef.current.resetFile();
        }

    }));



    const validateForm = () => {

        let inputs = getInputs();

        if (inputs.SenderAccount && inputs.IdColumn && inputs.SocialMediaType && (inputs.FileSelected || (inputs.OverrideMessage ? inputs.Message : inputs.MessageColumn))) {
            return true;
        }

        return false;

    }

    const getInputs = () => {
        return {
            IdColumn: formData.IdColumn ? formData.IdColumn : (props.headers && props.headers.length > 0 ? props.headers[0] : null),
            MessageColumn: formData.MessageColumn ? formData.MessageColumn : (props.headers && props.headers.length > 0 ? props.headers[0] : null),
            OverrideMessage: formData.OverrideMessage ? formData.OverrideMessage : false,
            SenderAccount: formData.SenderAccount ? formData.SenderAccount.Id : (props.socialAccounts && props.socialAccounts.length > 0 ? props.socialAccounts[0].Id : null),
            SocialMediaType: formData.SenderAccount && formData.SenderAccount.SocialMediaApplication ?
                formData.SenderAccount.SocialMediaApplication.SocialMediaTypeId :
                (props.socialAccounts && props.socialAccounts.length > 0 ? (props.socialAccounts[0].SocialMediaApplication ? props.socialAccounts[0].SocialMediaApplication.SocialMediaTypeId : null) : null),
            Tag: formData.Tag ? formData.Tag : "CONFIRMED_EVENT_UPDATE",
            Message: formData.Message,
            IdFormat: formData.ProfileIdFormat ? formData.ProfileIdFormat : "NONE",
            FileSelected: formData.FileSelected

        };
    }

    const validateIdFormat = (profileId, format, selectedSMType) => {

        if (format === 'NONE') {
            return { contactId: profileId, smType: selectedSMType };
        }
        else if (format === '<profileId>@<channel>.com') {
            let splitArr = profileId.split('@');

            if (splitArr.length === 2) {
                let smType = splitArr[1].toUpperCase().replace('.COM', '');

                if (smProviders.includes(smType)) {
                    return { contactId: splitArr[0], smType };
                } else {
                    return 'INVALID_PROVIDER';
                }

            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const onSenderAccountsSelected = (uploadData, senderAccounts, attachmentInfo) => {
        try {

            let dataUp = uploadData.map(data => {
                data.AccountId = senderAccounts[data.ContactType].SenderAccount.Id;
                data.Tag = data.ContactType === 'FB' ? senderAccounts[data.ContactType].Tag : "";
                data.SocialMediaType = data.ContactType;

                return data;
            })

            childRef.current.startUpload(dataUp, attachmentInfo);

        } catch (ex) {
            console.error(ex);
        }

    }

    const onSendPress = () => {
        try {
            let inputs = getInputs();
            let file = fileBrowserRef.current.getFile();
            let fileId = uuid();

            let batchId = uuid();

            let attachmentInfo = { file, fileId };

            //filter data

            let filteredData = props.tableData.data.filter(item => {

                if (!props.filterValues.includes(item.uniqueIndex)) {
                    return item;
                }

            })

            if (filteredData.length > 0) {

                let detectedSMTypes = [];

                let formatted = filteredData.map((contact, index) => {

                    let formattedIdInfo = validateIdFormat(contact[inputs.IdColumn], inputs.IdFormat, inputs.SocialMediaType);
                    if (formattedIdInfo === 'INVALID_PROVIDER') {
                        showAlert('error', 'Validation Error', `Contact with profile id "${contact[inputs.IdColumn]}" has a social media provider with no sender account configured`);
                        throw new Error('Invalid Id Column Format');

                    } else {
                        let tempMessage = inputs.OverrideMessage ? inputs.Message : contact[inputs.MessageColumn];
                        if (formattedIdInfo && (inputs.FileSelected || tempMessage)) {
                            if (formattedIdInfo.smType && !detectedSMTypes.includes(formattedIdInfo.smType)) {
                                detectedSMTypes.push(formattedIdInfo.smType);
                            }

                            let messageType = 'text';

                            if (file) {
                                if (formattedIdInfo.smType) {
                                    if (file.type && supportedTypes[formattedIdInfo.smType] && supportedTypes[formattedIdInfo.smType][file.type]) {
                                        messageType = supportedTypes[formattedIdInfo.smType][file.type];
                                    } else if (file.type && supportedTypes["ALL"] && supportedTypes["ALL"][file.type]) {
                                        messageType = supportedTypes["ALL"][file.type];
                                    } else {
                                        showAlert('error', 'Unsupported file type', `Contact with profile id "${contact[inputs.IdColumn]}" cannot be uploaded due to unsupported file type`);
                                        throw new Error('Unsupported file type for all types');
                                    }
                                } else {

                                    if (file.type && supportedTypes["ALL"] && supportedTypes["ALL"][file.type]) {
                                        messageType = supportedTypes["ALL"][file.type];
                                    } else {
                                        showAlert('error', 'Unsupported file type', `Contact with profile id "${contact[inputs.IdColumn]}" cannot be uploaded due to unsupported file type`);
                                        throw new Error('Unsupported file type for all types');
                                    }

                                }
                            }
                            let tempMsg = inputs.OverrideMessage ? inputs.Message : contact[inputs.MessageColumn];
                            return {
                                BatchId: batchId,
                                AccountId: inputs.SenderAccount,
                                ContactId: formattedIdInfo.contactId,
                                ContactType: formattedIdInfo.smType,
                                SocialMediaType: inputs.SocialMediaType,
                                Tag: inputs.SocialMediaType === 'FB' ? inputs.Tag : "",
                                Message: tempMsg ? tempMsg : "",
                                MessageType: messageType,
                                State: "UPLOADED",
                                createdby: "ESLAdmin"
                            }
                        } else {
                            showAlert('error', 'Validation Error', `Contact with profile id "${contact[inputs.IdColumn]}" does not comply with format selected, the sender account type or has empty details - please reupload and try again with correct formats`);
                            throw new Error('Invalid Id Column Format');
                        }

                    }


                })

                if (inputs.IdFormat === '<profileId>@<channel>.com') {
                    senderAccPickerRef.current.showPopup(props.socialAccounts, detectedSMTypes, formatted, attachmentInfo);
                } else {
                    childRef.current.startUpload(formatted, attachmentInfo);
                }



            } else {
                showAlert('error', 'Validation Error', `You havent selected any contacts to send`);
                throw new Error('Upload without selection');
            }



        } catch (ex) {
            console.error(ex);
        }

    }

    let isValidated = validateForm();

    return (

        <>
            <ContactUploader ref={childRef} onClose={() => {

            }} />
            <SenderAccountsPicker ref={senderAccPickerRef} onClose={() => {

            }} onSenderAccountsSelected={onSenderAccountsSelected} />
            <CRow>
                <CCol xs="12" sm="6" md="3">
                    <CFormGroup>
                        <CLabel htmlFor="out_dialer_csv_id_column">Id Column</CLabel>
                        <CSelect custom name="out_dialer_csv_id_column" id="out_dialer_csv_id_column" value={!formData.IdColumn ? (props.headers && props.headers.length > 0 ? props.headers[0] : "") : formData.IdColumn} onChange={(event) => {
                            onFormInputChanged("IdColumn", event.target.value);
                        }}>
                            {props.headers.map(header => <option key={`out_dialer_csv_id_column_${header}`}>{header}</option>)}
                        </CSelect>
                    </CFormGroup>
                </CCol>

                <CCol xs="12" sm="6" md="3">
                    <CFormGroup>
                        <CLabel htmlFor="out_dialer_csv_id_column_format">Id Formatting</CLabel>
                        <CSelect custom name="out_dialer_csv_id_column_format" id="out_dialer_csv_id_column_format" value={!formData.ProfileIdFormat ? 'NONE' : formData.ProfileIdFormat} onChange={(event) => {
                            onFormInputChanged("ProfileIdFormat", event.target.value);
                        }}>
                            <option value={`NONE`}>{`NONE`}</option>
                            <option value={`<profileId>@<channel>.com`}>{`<profileId>@<channel>.com`}</option>
                        </CSelect>
                    </CFormGroup>
                </CCol>
                <CCol xs="0" sm="0" md="1">

                </CCol>
                <CCol xs="12" sm="6" md="3">
                    <CFormGroup>
                        <CLabel htmlFor="out_dialer_csv_msg_column">Message Column</CLabel>
                        <CSelect custom name="out_dialer_csv_msg_column" id="out_dialer_csv_msg_column" value={!formData.MessageColumn ? (props.headers && props.headers.length > 0 ? props.headers[0] : "") : formData.MessageColumn} onChange={(event) => {
                            onFormInputChanged("MessageColumn", event.target.value);
                        }}>
                            {props.headers.map(header => <option key={`out_dialer_csv_msg_column_${header}`}>{header}</option>)}
                        </CSelect>
                    </CFormGroup>
                </CCol>
            </CRow>
            <CRow>
                <CCol xs="4" sm="4" md="2">
                    <CLabel htmlFor="out_dialer_search_message">Override Message</CLabel>
                </CCol>
                <CCol xs="2" sm="1" md="1">
                    <CSwitch className={'mx-1'} shape={'pill'} color={'success'} labelOn={'\u2713'} labelOff={'\u2715'} checked={!formData.OverrideMessage ? false : formData.OverrideMessage} onChange={(event) => {
                        onFormInputChanged("OverrideMessage", event.target.checked);
                    }} />
                </CCol>

            </CRow>
            <CRow>
                <CCol xs="12" sm="12" md="12">
                    <CTextarea
                        disabled={!formData.OverrideMessage}
                        name="out_dialer_csv_input_message"
                        id="out_dialer_csv_input_message"
                        rows="9"
                        placeholder="Type your message..."
                        value={!formData.Message ? "" : formData.Message}
                        onChange={(event) => {
                            onFormInputChanged("Message", event.target.value);
                        }}
                    />

                </CCol>


            </CRow>
            <br></br>
            <CRow>
                <CCol xs="12" sm="12" md="12" lg="12" xl="12">
                    <CFormGroup>
                        <CLabel htmlFor="out_dialer_attachment">Attachment</CLabel>
                        <br />
                        <FileBrowser ref={fileBrowserRef} onChange={(isSelected) => {
                            onFormInputChanged("FileSelected", isSelected);

                        }} />
                    </CFormGroup>

                </CCol>
            </CRow>
            <br></br>
            {formData.ProfileIdFormat !== '<profileId>@<channel>.com' && <CRow>
                <CCol xs="12" sm="12" md="12" lg="6" xl="5">
                    <SenderAccSelector labelName="" formData={formData} socialAccounts={props.socialAccounts} onFormInputChanged={onFormInputChanged} />

                </CCol>


            </CRow>}

            {/* <CRow>
                <CCol xs="12" sm="6" md="3">
                    <CFormGroup>
                        <CLabel htmlFor="out_dialer_csv_sender_acc">Sender Account</CLabel>
                        <CDropdown className="m-1">
                            <CDropdownToggle>
                                {getSMIcon(getAccountType())}
                                &nbsp;&nbsp;&nbsp;{formData.SenderAccount ? formData.SenderAccount.Name : (props.socialAccounts && props.socialAccounts.length > 0 ? props.socialAccounts[0].Name : "")}
                            </CDropdownToggle>
                            <CDropdownMenu>
                                {props.socialAccounts.map((acc, index) => <CDropdownItem key={`out_dialer_csv_sender_acc${acc.Id}`} onClick={() => {
                                    onFormInputChanged("SenderAccount", acc);
                                }}>
                                    {getSMIcon(acc.SocialMediaApplication.SocialMediaTypeId)}
                                &nbsp;&nbsp;&nbsp;{acc.Name}</CDropdownItem>)}

                            </CDropdownMenu>
                        </CDropdown>

                    </CFormGroup>
                </CCol>

                {getAccountType() === 'FB' && <CCol xs="12" sm="6" md="3">
                    <CFormGroup>
                        <CLabel htmlFor="out_dialer_csv_sender_acc_tag">Tag</CLabel>
                        <CSelect custom name="out_dialer_csv_sender_acc_tag" id="out_dialer_csv_sender_acc_tag" onChange={(event) => {
                            onFormInputChanged("Tag", event.target.value);
                        }}>
                            <option value={`CONFIRMED_EVENT_UPDATE`}>{`CONFIRMED_EVENT_UPDATE`}</option>
                            <option value={`POST_PURCHASE_UPDATE`}>{`POST_PURCHASE_UPDATE`}</option>
                            <option value={`ACCOUNT_UPDATE`}>{`ACCOUNT_UPDATE`}</option>
                        </CSelect>
                    </CFormGroup>
                </CCol>}
            </CRow> */}
            <br></br>
            <CRow>
                <CCol xs="6" sm="3" md="2" lg="2" xl="2">
                    <CButton className={`${isValidated ? 'sm-cursor-pointer' : 'sm-cursor-na'}`} disabled={isValidated ? false : true} block color={isValidated ? "primary" : "secondary"} onClick={onSendPress}>Send</CButton>
                </CCol>

            </CRow>
        </>



    )
});

export default DialerCSVInputForm;
