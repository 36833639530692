import React, { forwardRef, useState, useImperativeHandle } from 'react';
import {
    CInput,
    CListGroup,
    CListGroupItem
} from '@coreui/react';
import { Scrollbars } from "react-custom-scrollbars";

const Typeahead = forwardRef((props, ref) => {

    const [itemList, setItemList] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useImperativeHandle(ref, () => ({

        setDropdownData(items) {
            setItemList(items);
        }

    }));

    const onChange = (e) => {
        setInputValue(e.target.value);

        if (props.onValueChanged && e.target.value.length >= props.minLength) {

            props.onValueChanged(e.target.value);
        }

    }

    return (

        <>
            <CInput id={props.id} placeholder={props.placeholder} required={props.required} value={inputValue} onChange={onChange} />
            {itemList.length > 0 && <div className="sm-typeahead">
                <div className="inner">
                    <Scrollbars
                        thumbMinSize={0}
                        style={{ height: 100 }}>
                        {<CListGroup>
                            {
                                itemList.map(item => {
                                    return (<CListGroupItem href="#" onClick={() => {
                                        setInputValue(item[props.dropDownDisplayKey]);
                                        props.onItemSelected(item);
                                        setItemList([]);
                                    }}>{item[props.dropDownDisplayKey]}</CListGroupItem>);
                                })
                            }
                        </CListGroup>}
                    </Scrollbars>

                </div>

            </div>}
        </>

    )
});

export default Typeahead;
