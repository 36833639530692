import './App.css';
import '../node_modules/@coreui/coreui/dist/css/coreui.min.css';
import '../node_modules/antd/dist/antd.css';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Navigation from './components/layout/navigation/navigation';
import Wrapper from './components/layout/wrapper/wrapper';
import SMAlert from './components/reusables/notifications/alerts';
import Login from './components/login/login';
import Console from './components/layout/console';
import { getUser } from './util/session';

function App() {
  return (
    <>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/console" component={(path) => {
          return !getUser() ? (
            <Redirect to="/login" />
          ) : (
              <Console />
            );
        }}>
        </Route>
        <Route exact path="/">
          <Redirect to="/console" />
        </Route>
        <Route path="*">
          <Redirect to="/console" />
        </Route>
      </Switch>

      {/* <div className="c-app">
      <SMAlert />
      <Navigation />
      <Wrapper />
    </div> */}
    </>
  );
}

export default App;
