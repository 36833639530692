import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton
} from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { logout } from '../../../util/session';

const LogoutButton = () => {

    let history = useHistory();

    const [modalState, setModalState] = useState(false);

    const closeModal = () => {
        setModalState(false);
    }

    const openModal = () => {
        setModalState(true);
    }

    return (
        <>
            {modalState && <CModal
                show={modalState}
                onClose={closeModal}
                centered={true}
                closeOnBackdrop={false}
            >
                <CModalHeader className="sm-modal-header" closeButton>Log Out</CModalHeader>
                <CModalBody>
                    Do you wish to logout from application ?
                </CModalBody>
                <CModalFooter>

                    <CButton color="danger" onClick={() => {
                        closeModal();
                    }}>Cancel</CButton>
                    <CButton color="success" onClick={() => {
                        closeModal();
                        logout();
                        history.push('/login');
                    }}>Log Out</CButton>



                </CModalFooter>
            </CModal>}
            <FontAwesomeIcon icon={faPowerOff} className="fa-2x sm-logout" onClick={openModal} />
        </>

    )
}

export default LogoutButton
