import CryptoJS from 'crypto-js';
import SecureStorage from 'secure-web-storage';
import uuid from 'uuid';
const jwt = require("jsonwebtoken");

const LOGGED_USER = "@@logged_user";
const TOKEN = "@@token";

const SECRET_KEY = "tetherfi";

var secureStorage = new SecureStorage(sessionStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);

        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);

        data = data.toString();

        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);

        data = data.toString(CryptoJS.enc.Utf8);

        return data;
    },
});

export const setUser = (username) => {
    secureStorage.setItem(LOGGED_USER, username);
}

export const getUser = () => {
    const user = secureStorage.getItem(LOGGED_USER);
    return user;
}

export const getToken = () => {
    const token = secureStorage.getItem(TOKEN);
    return token;
}

export const logout = () => {
    secureStorage.removeItem(LOGGED_USER);
}

export const login = async (loginData) => {

    return new Promise((resolve, reject) => {

        if (loginData.Username === 'admin' && loginData.Password === 'admin@123') {

            const token = jwt.sign({ username: loginData.Username }, 'tetherfi', {
                expiresIn: `24h`, //was 5minutes
                subject: 'login',
                jwtid: uuid(),
                issuer: 'admin',
                audience: loginData.Username,
            });

            setUser(loginData.Username);

            secureStorage.setItem(TOKEN, token);
            setTimeout(() => {
                resolve(true);
            }, 2000);

        } else {
            resolve(false);
        }

    })

}