import uuid from "uuid/v1";
import { mediaEndpointType, mediaUploaderUrl, useProxyForUpload } from '../config/config';
import { getToken } from '../util/session';

export const uploadFile = (file, refId) => {
    if (mediaEndpointType === 'ESL') {
        return uploadFileToESLUploader(file, refId);
    } else {
        return uploadFileToMediaUploader(file, refId);
    }

}

export const uploadFileToMediaUploader = (
    file,
    refId
) => {
    return new Promise((resolve, reject) => {

        let fd = new FormData();
        fd.append("file", file);
        fd.append("organization_id", "tetherfisl");
        fd.append("interaction_id", uuid());
        fd.append("uploaded_by", "SMAdmin");
        fd.append("conv_id", "tetherfi");
        fd.append("mimetype", file.type);

        fetch(`${mediaUploaderUrl}/upload`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            body: fd
        }).then(res => {
            return res.json();

        }).then(response => {
            if (response.isSuccess) {
                resolve({ streamUrl: response.result.fileUrl ? response.result.fileUrl : response.result.streamURL });
            } else {
                reject(new Error('Error uploading file'));
            }
        }).catch(ex => {

            reject(ex);
            console.error('[fileApi.uploadFileToESLUploader] - Error uploading file', ex);

        });

        /* fetch
            .post(`${mediaUploaderUrl}/upload`, fd, config)
            .then(response => {
                if (response.data.isSuccess) {
                    resolve({ streamUrl: `https://social-chat-image-storage-001-kln7ur7dda-an.a.run.app/get/image/${response.data.result}` });
                } else {
                    reject(new Error('Error uploading file'));
                }

            })
            .catch(error => {
                reject(error);
                console.log(error);
            }); */

    });

};

export const uploadFileToESLUploader = (
    file, refId
) => {
    return new Promise(async (resolve, reject) => {

        try {
            const toArrayBuffer = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            function _arrayBufferToBase64(buffer) {
                var binary = '';
                var bytes = new Uint8Array(buffer);
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                return window.btoa(binary);
            }

            let arrayBuffer = await toArrayBuffer(file);

            let base64String = _arrayBufferToBase64(arrayBuffer);

            const uploadData = {
                imageContent: base64String,
                metaData: {
                    organization_id: "tetherfisl",
                    interaction_id: uuid(),
                    conv_id: "tetherfi",
                    uploaded_by: "SMAdmin",
                    imagetype: file.type
                },
                key: refId
            };

            let proxyUrl = 'https://cors-anywhere.herokuapp.com/';

            let url = useProxyForUpload === 'YES' ? `${proxyUrl}${mediaUploaderUrl}/api/v1/store/base64/image` : `${mediaUploaderUrl}/api/v1/store/base64/image`

            fetch(`${url}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(uploadData)
            }).then(res => {
                return res.json();

            }).then(data => {
                resolve({ streamUrl: `${mediaUploaderUrl}/get/image/${data.fileName}` });
            }).catch(ex => {

                reject(ex);
                console.error('[fileApi.uploadFileToESLUploader] - Error uploading file', ex);

            });

        } catch (ex) {
            reject(ex);
            console.error('Error uploading file', ex);
        }



    });

};
