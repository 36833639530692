import React, { useState, useEffect, useRef } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CDataTable,
    CButton,
    CBadge,
    CCollapse,
    CInputCheckbox,
    CSwitch,
    CLabel
} from '@coreui/react';
import Papa from 'papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import DialerCSVInputForm from './dialerCSVInputForm';
import { ManagementAPI } from '../../../../services/managementApi';
import CSVFileInput from './csvFileInput';
import { csvContactsPerPage } from '../../../../config/config';
import { showAlert } from '../../../../util/uiNotifications';

const reader = new FileReader();

const OutboundDialerCSVImport = (props) => {

    const childRef = useRef();

    const [tableData, setTableData] = useState({ headers: [], fields: [], data: [] });
    const [socialAccounts, setSocialAccounts] = useState([]);
    const [details, setDetails] = useState([]);
    const [markedStatus, setMarkedStatus] = useState(true);

    const [visibility, setVisibility] = useState(true);

    useEffect(() => {

        if (!visibility) {
            setVisibility(true);
        }

    }, [visibility]);

    const onMarkPress = (isMarked) => {
        setMarkedStatus(isMarked);
        if (isMarked) {
            setDetails([]);
        } else {
            let indexArr = tableData.data.map(val => val.uniqueIndex);
            setDetails(indexArr);
        }

    }

    const resetForm = () => {
        setTableData({ headers: [], fields: [], data: [] });
        setDetails([]);
        setMarkedStatus(true);
        setVisibility(false);
    }

    const toggleDetails = (index, isChecked) => {
        const position = details.indexOf(index)
        let newDetails = details.slice()
        if (position !== -1) {
            newDetails.splice(position, 1)
        } else {
            newDetails = [...details, index]
        }
        setDetails(newDetails)
    }

    let tableFilter = {
        placeholder: 'Search Here'
    }

    useEffect(() => {

        ManagementAPI.getSocialAccounts().then(accounts => {
            setSocialAccounts(accounts);
        }).catch(ex => {
            console.error(ex);
            showAlert('error', 'Error', 'Error loading social media accounts');
        })

        reader.addEventListener('load', (evt) => {

            const regex = /^(?=\n)$|^\s*|\s*$|\n\n+/gm;

            let parsedCSV = Papa.parse(evt.target.result.replace(regex, ""));

            let headerValues = [];
            let dataValues = [];

            let rowsArray = parsedCSV.data;

            if (rowsArray && rowsArray.length > 0) {
                headerValues = rowsArray[0];

                for (let i = 1; i < rowsArray.length; i++) {
                    let data = rowsArray[i];
                    let obj = { uniqueIndex: i };
                    for (let j = 0; j < headerValues.length; j++) {
                        if (headerValues[j]) {
                            obj[headerValues[j].trim()] = data[j] ? data[j].trim() : '';
                        }
                    }
                    dataValues.push(obj);
                }

                let fields = headerValues.reduce((acc, hdr) => {
                    let obj = {};
                    obj.key = hdr;
                    acc.push(obj);
                    return acc;
                }, [{
                    key: 'is_active',
                    label: '',
                    _style: { width: '1%' },
                    sorter: false,
                    filter: false
                }])

                childRef.current && childRef.current.resetForm();

                setDetails([]);
                setMarkedStatus(true);
                setTableData({ headers: headerValues, fields: fields, data: dataValues });
            } else {
                setDetails([]);
                setMarkedStatus(true);
                setTableData({ headers: [], fields: [], data: [] });

            }
        });

        return () => {
            reader.removeEventListener('load', () => {
                console.log('UNMOUNTING');
            });
        }


    }, []);

    const onFileSelected = (event) => {

        console.log('File Selected');

        if (event.target.files.length > 0) {

            let file = event.target.files[0];

            let fileSplit = file.name.split('.');

            if (fileSplit.length >= 2 && fileSplit[1] === 'csv') {

                reader.readAsText(event.target.files[0]);

            } else {
                showAlert('error', 'Invalid Format', 'The file you have selected is not a csv type file');
            }

            //check file extension



        }
    };

    let rows = tableData.fields.length;

    return (
        <>
            <CRow>
                <CCol xs="12" sm="12" md="12">
                    <CCard>
                        <CCardHeader className='sm-card-header'>
                            CSV Import
                    </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol xs="6" sm="3" md="3" lg="3" xl="2">

                                    <CSVFileInput onFileSelected={onFileSelected} />
                                </CCol>
                                <CCol xs="6" sm="3" md="2" lg="2" xl="1">
                                    <CButton className={`btn-danger sm-float-right ${rows > 0 ? 'sm-cursor-pointer' : 'sm-cursor-na'}`} disabled={rows > 0 ? false : true} onClick={resetForm}>Reset</CButton>
                                </CCol>
                                <CCol xs="0" sm="3" md="4" lg="4" xl="7">

                                </CCol>
                                <CCol xs="12" sm="3" md="3" lg="3" xl="2">
                                    {!markedStatus && <CButton className={`btn-success sm-float-right ${rows > 0 ? 'sm-cursor-pointer' : 'sm-cursor-na'}`} disabled={rows > 0 ? false : true} onClick={() => { onMarkPress(!markedStatus) }}><FontAwesomeIcon icon={faCheckSquare} className="fa-md" />&nbsp;&nbsp;Mark All</CButton>}
                                    {markedStatus && <CButton className={`btn-success sm-float-right ${rows > 0 ? 'sm-cursor-pointer' : 'sm-cursor-na'}`} disabled={rows > 0 ? false : true} onClick={() => { onMarkPress(!markedStatus) }}><FontAwesomeIcon icon={faTimes} className="fa-md" />&nbsp;&nbsp;Unmark All</CButton>}

                                </CCol>

                            </CRow>
                            <CRow className="mt-4">
                                <CCol xs="12" sm="12" md="12">
                                    <CCard>
                                        <CCardBody>
                                            {visibility && <CDataTable
                                                items={tableData.data}
                                                fields={tableData.fields}
                                                itemsPerPage={csvContactsPerPage}
                                                pagination
                                                tableFilter={tableFilter}
                                                scopedSlots={{
                                                    'is_active':
                                                        (item, index) => (
                                                            <td className="py-2">
                                                                <CInputCheckbox className="sm-csv-view-checkbox" checked={details.includes(item.uniqueIndex) ? false : true} onChange={(event) => {
                                                                    toggleDetails(item.uniqueIndex, event.target.checked);
                                                                }}>

                                                                </CInputCheckbox>
                                                            </td>
                                                        )
                                                }}
                                            />}
                                        </CCardBody>
                                    </CCard>
                                </CCol>

                            </CRow>
                            {tableData.headers.length > 0 && <>
                                <DialerCSVInputForm ref={childRef} tableData={tableData} filterValues={details} headers={tableData.headers} socialAccounts={socialAccounts} />
                            </>}

                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </>


    )
};

export default OutboundDialerCSVImport;
