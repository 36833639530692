import * as types from "./actionTypes";

export function navigationWebVisibilityChanged() {
    return { type: types.CHANGE_WEB_NAV_VISIBILITY };
}

export function navigationMobileVisibilityChanged() {
    return { type: types.CHANGE_MOBILE_NAV_VISIBILITY };
}

export function navigationMobileHide() {
    return { type: types.HIDE_MOBILE_NAV };
}