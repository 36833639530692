import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow
} from '@coreui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/img/tetherfi_logo.png';
import { login } from '../../util/session';
import { useLogo } from '../../config/config';

const Login = () => {

    let history = useHistory();

    const [loginData, setLoginData] = useState({});
    const [loginState, setLoginState] = useState('init');

    const onFormInputChanged = (name, value) => {
        setLoginData(prevVal => { return { ...prevVal, [name]: value } })
    }

    const loginClicked = async () => {
        setLoginState('loading');
        let result = await login(loginData);

        if (result) {
            setLoginState('success');
            history.push('/console');
        } else {
            setLoginState('fail');
        }
    }

    const keyPressHandler = (event) => {
        if (event.key === "Enter") {
            loginClicked();
        }
    }

    const getLoginStatusMessage = () => {
        if (loginState === 'init') {
            return (<div style={{ borderRadius: "4rem", marginLeft: "10%", marginRight: "10%", color: "#2b2b52" }}>Please login to continue</div>);
        } else if (loginState === 'loading') {
            return (<div style={{ borderRadius: "4rem", marginLeft: "10%", marginRight: "10%", color: "#2b2b52" }}>Please wait....</div>);
        } else if (loginState === 'success') {
            return (<div style={{ borderRadius: "4rem", marginLeft: "10%", marginRight: "10%", color: "#2b2b52" }}>Login success</div>);
        } else if (loginState === 'fail') {
            return (<div style={{ borderRadius: "4rem", marginLeft: "10%", marginRight: "10%", color: "red" }}>Login failed, please try again</div>);
        }
    }

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="8">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm>
                                        <h1>Login</h1>
                                        <p className="text-muted">Sign In to your account</p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <FontAwesomeIcon icon={faUser} className="fa-lg" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput type="text" placeholder="Username" autoComplete="username" onChange={(event) => {
                                                onFormInputChanged("Username", event.target.value);
                                            }} />
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <FontAwesomeIcon icon={faKey} className="fa-lg" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <input class="form-control" type="password" placeholder="Password" autocomplete="current-password" onKeyPress={keyPressHandler} onChange={(event) => {
                                                onFormInputChanged("Password", event.target.value);
                                            }} />
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs="6">
                                                <CButton color="primary" className="px-4" onClick={loginClicked}>{loginState === 'loading' ? 'Please wait...' : 'Login'}</CButton>
                                            </CCol>
                                            {/* <CCol xs="6" className="text-right">
                                                <CButton color="link" className="px-0">Forgot password?</CButton>
                                            </CCol> */}
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                            <CCard className="text-white bg-secondary py-5 d-md-down-none" style={{ width: '44%' }}>
                                <CCardBody className="text-center">
                                    <div className={`${useLogo === 'YES' ? '' : 'sm-login-nologo'}`}>
                                        {useLogo === 'YES' && <><img style={{ height: "75px", width: "175px" }} src={logo} />
                                            <br /><br /></>}
                                        <h5>Social Media Admin</h5>
                                        <br /><br />
                                        {getLoginStatusMessage()}
                                    </div>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default Login
