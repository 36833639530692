import React, { forwardRef, useState, useImperativeHandle } from 'react';
import {
    CProgress,
    CProgressBar
} from '@coreui/react';

const ProgressBar = forwardRef((props, ref) => {

    const [uploadedCount, setUploadedCount] = useState(0);
    const [isError, setIsError] = useState(false);

    useImperativeHandle(ref, () => ({

        contactBatchUploaded(count) {
            setUploadedCount(prevVal => prevVal + count);
        },
        haltProcessOnError() {
            setIsError(true);
        }

    }));

    let percentage = Math.round((uploadedCount / props.totalCount) * 100);

    if (percentage === 100) {
        props.onProgressCompleted && props.onProgressCompleted();
    }

    return (

        <>
            <p>{`Completed ${uploadedCount} of ${props.totalCount}`}</p>
            <CProgress className="mb-1 bg-white" showPercentage={true}>
                <CProgressBar
                    showValue
                    value={percentage}
                    color={`${isError ? 'error' : 'primary'}`}
                    height="30px"
                />
            </CProgress>
        </>

    )
});

export default ProgressBar;
