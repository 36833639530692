import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuList from '../../subcomponents/menu/menuList';
import logo from '../../../assets/img/tetherfi.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faIdBadge, faSearch, faFileCsv, faChartBar } from '@fortawesome/free-solid-svg-icons';
import MenuItem from "../../subcomponents/menu/menuItem";
import * as panelActions from '../../../redux/actions/panelActions';
import * as selectedPageActions from '../../../redux/actions/selectedPageActions';

const Navigation = (props) => {

    return (

        <div className={`c-sidebar c-sidebar-dark c-sidebar-fixed ${props.nav_panel.visibility_state_web ? 'c-sidebar-lg-show' : ''} ${props.nav_panel.visibility_state_mobile ? 'c-sidebar-show' : ''}`} id="sidebar">
            <div className="c-sidebar-brand d-md-down-none">
                <img style={{ height: "35px", width: "35px", marginLeft: "-30px", marginRight: "20px" }} src={logo} />
                Social Media Admin
            </div>
            <ul className="c-sidebar-nav ps ps--active-y">
                <MenuItem isParent={true} id='nav_console_dashboard' displayName='Dashboard' icon={<FontAwesomeIcon icon={faChartBar} className="fa-lg" />} link='/console/dashboard' onClick={() => {
                    props.panel_actions.navigationMobileHide();
                    props.selected_page_actions.pageSelected('nav_console_dashboard', 'nav_dashboard');
                }} />
                <MenuList id='nav_outbound-dialer' displayName='Outbound Dialer' icon={<FontAwesomeIcon icon={faLocationArrow} className="fa-lg" />} menuItems={[
                    { id: 'nav_console_outbound-dialer_search-user', displayName: 'Search', icon: (<FontAwesomeIcon icon={faSearch} className="fa-lg" />), link: '/console/outbound-dialer/search-user' },
                    { id: 'nav_console_outbound-dialer_csv-import', displayName: 'CSV Import', icon: (<FontAwesomeIcon icon={faFileCsv} className="fa-lg" />), link: '/console/outbound-dialer/csv-import' }
                ]} />
                <MenuItem isParent={true} id='nav_console_social-app-manager' displayName='Social App Manager' icon={<FontAwesomeIcon icon={faIdBadge} className="fa-lg" />} link='/console/social-app-manager' onClick={() => {
                    props.panel_actions.navigationMobileHide();
                    props.selected_page_actions.pageSelected('nav_console_social-app-manager', 'nav_social-app-manager');
                }} />

            </ul>
            <div className='sm-version'>v 2.0.6.4</div>

        </div>



    )
};

function mapStateToProps(state) {
    return {
        nav_panel: state.panel.nav_panel
    };

}

function mapDispatchToProps(dispatch) {
    return {
        panel_actions: bindActionCreators(panelActions, dispatch),
        selected_page_actions: bindActionCreators(selectedPageActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
