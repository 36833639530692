import React from 'react';
import { connect } from 'react-redux';
import {
    Link
} from "react-router-dom";

const MenuItem = (props) => {

    return (

        <li key={props.id} className={`c-sidebar-nav-item ${props.selected_menu === props.id ? 'sm-menu-item' : ''}`}>
            <Link to={props.link}>
                <div className={`${props.isParent ? '' : 'sm-menu-padding'} c-sidebar-nav-link`} onClick={props.onClick}>
                    {props.icon}
                    <div className={`${props.isParent ? 'sm-menu-list-icon' : 'sm-menu-icon'}`}>{props.displayName}</div>
                </div>
            </Link>
        </li >

    )
};

function mapStateToProps(state) {
    return {
        selected_menu: state.selected_page.selectedMenu
    };
}

export default connect(mapStateToProps)(MenuItem);
