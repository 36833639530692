import React, { Component } from 'react';
import Navigation from './navigation/navigation';
import Wrapper from './wrapper/wrapper';
import SMAlert from '../reusables/notifications/alerts';

class Console extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="c-app">
                <SMAlert />
                <Navigation />
                <Wrapper />
            </div>
        );

    }


}

export default Console;