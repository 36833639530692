import * as types from "../actions/actionTypes";
import produce from "immer";

const initialState = { selectedMenu: "", parentNav: "" };

export const selectedPageReducer = produce((draft = initialState, action) => {
    switch (action.type) {
        case types.PAGE_SELECTED:
            {
                draft.selectedMenu = action.selectedMenu;
                draft.parentNav = action.parentNav;
                return draft;

            }
        default:
            return draft;
    }
});