import React, { useRef, useState, useEffect } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CCollapse,
    CListGroup,
    CListGroupItem,
    CButton,
    CBadge
} from '@coreui/react';
import produce from "immer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faEdit, faQuestionCircle, faChevronUp, faChevronDown, faBan, faSms, faCopy } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLine, faViber, faWeixin, faTelegram, faTwitter, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { ManagementAPI } from '../../../services/managementApi';
import ApplicationModal from './applicationModal';
import AccountModal from './accountModal';
import { showAlert } from '../../../util/uiNotifications';
import { callbackApiUrl } from '../../../config/config';

const SocialAppManager = (props) => {

    const childRef = useRef();
    const childRefAcc = useRef();

    const [smApplications, setSMApplications] = useState({});
    const [accordion, setAccordion] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(true);

    useEffect(() => {

        ManagementAPI.getSocialApplications().then(apps => {
            setSMApplications(apps);
            setIsDataLoading(false);
        }).catch(ex => {
            setSMApplications({});
            setIsDataLoading(false);
            showAlert('error', 'Error', `Error loading social media applications`);
            console.error(ex);
        })

    }, []);

    const onSaveApplication = (app) => {
        setSMApplications(produce(draft => {
            draft[app.Id] = app;
            return draft;
        }))

    }

    const onUpdateApplication = (app) => {
        setSMApplications(produce(draft => {
            draft[app.Id] = app;
            return draft;
        }))

    }

    const onSaveAccount = (acc) => {

        setSMApplications(produce(draft => {
            if (!draft[acc.SocialMediaApplicationId].SocialMediaAccounts)
            {
                draft[acc.SocialMediaApplicationId].SocialMediaAccounts = {};
            }

            draft[acc.SocialMediaApplicationId].SocialMediaAccounts[acc.Id] = acc;
            return draft;
        }))


    }

    const onUpdateAccount = (acc) => {

        setSMApplications(produce(draft => {
            if (!draft[acc.SocialMediaApplicationId].SocialMediaAccounts)
            {
                draft[acc.SocialMediaApplicationId].SocialMediaAccounts = {};
            }

            draft[acc.SocialMediaApplicationId].SocialMediaAccounts[acc.Id] = acc;
            return draft;
        }))
    }

    const getSMIcon = (type) => {
        switch (type)
        {
            case 'FB': {
                return (<FontAwesomeIcon icon={faFacebook} className="fa-lg sm-app-mgr-icon-margin-right sm-fb" />);
            }
            case 'LINE': {
                return (<FontAwesomeIcon icon={faLine} className="fa-lg sm-app-mgr-icon-margin-right sm-line" />);
            }
            case 'WHATSAPP': {
                return (<FontAwesomeIcon icon={faWhatsapp} className="fa-lg sm-app-mgr-icon-margin-right sm-whatsapp" />);
            }
            case 'TWITTER': {
                return (<FontAwesomeIcon icon={faTwitter} className="fa-lg sm-app-mgr-icon-margin-right sm-twitter" />);
            }
            case 'VIBER': {
                return (<FontAwesomeIcon icon={faViber} className="fa-lg sm-app-mgr-icon-margin-right sm-viber" />);
            }
            case 'TELEGRAM': {
                return (<FontAwesomeIcon icon={faTelegram} className="fa-lg sm-app-mgr-icon-margin-right sm-telegram" />);
            }
            case 'WE': {
                return (<FontAwesomeIcon icon={faWeixin} className="fa-lg sm-app-mgr-icon-margin-right sm-wechat" />);
            }
            case 'TWILIO': {
                return (<FontAwesomeIcon icon={faSms} className="fa-lg sm-app-mgr-icon-margin-right sm-twilio" />);
            }
            case 'INSTAGRAM': {
                return (<FontAwesomeIcon icon={faInstagram} className="fa-lg sm-app-mgr-icon-margin-right sm-instagram" />);
            }
            default: {
                return (<FontAwesomeIcon icon={faQuestionCircle} className="fa-lg sm-app-mgr-icon-margin-right sm-unknown" />);
            }
        }

    }

    const getStatusBadge = (status) => {

        switch (status)
        {
            case true: {
                return (<CBadge className='sm-float-right' color='success'>
                    Active
                </CBadge>);
            }
            case false: {
                return (<CBadge className='sm-float-right' color='danger'>
                    Inactive
                </CBadge>);
            }
            default: {
                return (<CBadge className='sm-float-right' color='danger'>
                    Inactive
                </CBadge>);
            }
        }


    }

    let appCount = Object.keys(smApplications).length;

    let keyArray = Object.keys(smApplications).sort(function (a, b) {

        let name_a = smApplications[a].Name ? smApplications[a].Name.toUpperCase() : "";
        let name_b = smApplications[b].Name ? smApplications[b].Name.toUpperCase() : "";

        if (name_a < name_b)
        {
            return -1;
        }
        if (name_a > name_b)
        {
            return 1;
        }

        return 0;
    });

    let appListElement = keyArray.map(key => {
        return (
            <CCard key={`applist_${key}`} id={`applist_${key}`} className="sm-app-mgr-app-header mb-0">
                <CCardHeader id={`appHeader_${key}`} className="sm-card-header">
                    <CRow>
                        <CCol xs="7" sm="9" md="10" onClick={() => setAccordion(accordion === key ? null : key)}>

                            <div className="sm-cursor-pointer sm-app-mgr-inline">
                                {getSMIcon(smApplications[key].SocialMediaTypeId)}
                                <h5 className="m-0 p-0">{smApplications[key].Name}</h5>


                            </div>

                        </CCol>
                        <CCol xs="5" sm="3" md="2" className="sm-app-mgr-iconset">
                            {accordion === key && <FontAwesomeIcon icon={faChevronUp} className="sm-cursor-pointer fa-md sm-app-mgr-edit sm-float-right" onClick={() => setAccordion(accordion === key ? null : key)} />}
                            {accordion !== key && <FontAwesomeIcon icon={faChevronDown} className="sm-cursor-pointer fa-md sm-app-mgr-edit sm-float-right" onClick={() => setAccordion(accordion === key ? null : key)} />}
                            <FontAwesomeIcon icon={faEdit} className="sm-cursor-pointer fa-md sm-app-mgr-edit sm-float-right" onClick={() => {
                                childRef.current.openModal(smApplications[key]);
                            }} />
                            {getStatusBadge(smApplications[key].Active)}

                        </CCol>
                    </CRow>


                </CCardHeader>
                <CCollapse show={accordion === key}>
                    <CCardBody>
                        <CListGroup>
                            <h5 className="m-0 p-0">Information</h5>
                            <br />
                            <CListGroupItem>
                                <CRow>
                                    <CCol xs="5" sm="3" md="3">
                                        App Id
                                    </CCol>
                                    <CCol xs="7" sm="9" md="9">
                                        {smApplications[key].AppId}
                                    </CCol>
                                </CRow>
                            </CListGroupItem>
                            {/* <CListGroupItem>
                                <CRow>
                                    <CCol xs="3" sm="3" md="3">
                                        App Secret
                                    </CCol>
                                    <CCol xs="9" sm="9" md="9">
                                        {smApplications[key].AppSecret}
                                    </CCol>
                                </CRow>
                            </CListGroupItem> */}
                            <CListGroupItem>
                                <CRow>
                                    <CCol xs="5" sm="3" md="3">
                                        Created By
                                    </CCol>
                                    <CCol xs="7" sm="9" md="9">
                                        {smApplications[key].createdby}
                                    </CCol>
                                </CRow>
                            </CListGroupItem>
                            <br />
                            <div>
                                <h5 className="m-0 p-0">Accounts</h5>&nbsp;&nbsp;
                                <CButton className="btn btn-primary btn-sm sm-float-right sm-app-mgr-acc-but-margin" onClick={() => {
                                    childRefAcc.current.openModal(key, null, smApplications[key].SocialMediaTypeId);
                                }}><FontAwesomeIcon icon={faPlusSquare} className="fa-sm" /> New Account</CButton>

                            </div>



                            <br />
                            <CRow>
                                {Object.keys(smApplications[key].SocialMediaAccounts).length === 0 && <CCol xs="12" sm="12" md="12">
                                    <CCard>
                                        <CCardBody className="sm-text-align-center">
                                            <FontAwesomeIcon icon={faBan} className="fa-md" />&nbsp;&nbsp;
                                            No accounts found - You may add new accounts by pressing the New Account button
                                        </CCardBody>
                                    </CCard>
                                </CCol>}
                                {

                                    Object.keys(smApplications[key].SocialMediaAccounts).map(accKey => {

                                        let callbackUrl = smApplications[key].SocialMediaTypeId === 'VIBER' ? smApplications[key].SocialMediaAccounts[accKey].CallbackUrl : `${callbackApiUrl}/Callback/Chat/${smApplications[key].SocialMediaTypeId}/Event/${smApplications[key].SocialMediaAccounts[accKey].Id}`;

                                        return (
                                            <CCol key={`acclist_${accKey}`} id={`acclist_${accKey}`} xs="12" sm="12" md="6" className="sm-app-mgr-acc">
                                                <CListGroupItem className="sm-acc-card-header">
                                                    <CRow>
                                                        <CCol xs="7" sm="8" md="9">
                                                            <h5 className="m-0 p-0">{smApplications[key].SocialMediaAccounts[accKey].Name}</h5>
                                                        </CCol>
                                                        <CCol xs="5" sm="4" md="3">
                                                            <FontAwesomeIcon icon={faEdit} style={{ cursor: "pointer" }} className="fa-md sm-app-mgr-edit sm-float-right" onClick={() => {
                                                                childRefAcc.current.openModal(key, smApplications[key].SocialMediaAccounts[accKey], smApplications[key].SocialMediaTypeId);
                                                            }} />
                                                            {getStatusBadge(smApplications[key].SocialMediaAccounts[accKey].Active)}

                                                        </CCol>
                                                    </CRow>
                                                </CListGroupItem>
                                                <CListGroupItem>
                                                    <CRow>
                                                        <CCol xs="5" sm="3" md="3">
                                                            Account Id
                                                        </CCol>
                                                        <CCol xs="7" sm="9" md="9">
                                                            {smApplications[key].SocialMediaAccounts[accKey].Id}
                                                        </CCol>
                                                    </CRow>
                                                </CListGroupItem>
                                                <CListGroupItem>
                                                    <CRow>
                                                        <CCol xs="5" sm="3" md="3">
                                                            Page Id
                                                        </CCol>
                                                        <CCol xs="7" sm="9" md="9">
                                                            {smApplications[key].SocialMediaAccounts[accKey].PageId}
                                                        </CCol>
                                                    </CRow>
                                                </CListGroupItem>
                                                <CListGroupItem>
                                                    <CRow>
                                                        <CCol xs="5" sm="3" md="3">
                                                            Callback Url
                                                        </CCol>
                                                        <CCol xs="7" sm="9" md="9">
                                                            <CRow>
                                                                <CCol xs="11" sm="11" md="11">{callbackUrl}</CCol>
                                                                <CCol xs="1" sm="1" md="1" style={{ paddingTop: "5px" }} title="Copy to clipboard">
                                                                    <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCopy} className="fa-md sm-app-mgr-edit sm-float-right" onClick={() => {
                                                                        navigator.clipboard.writeText(callbackUrl);
                                                                        showAlert('success', 'Success', `Url copied to clipboard`);
                                                                    }} />
                                                                </CCol>

                                                            </CRow>


                                                        </CCol>
                                                    </CRow>
                                                </CListGroupItem>
                                                {/* <CCard className="mb-0">
                                                <CCardHeader id={`accHeader_${accKey}`}>
                                                    <CRow>
                                                        <CCol xs="9" sm="9" md="11">
                                                            <h5 className="m-0 p-0">{smApplications[key].SocialMediaAccounts[accKey].Name}</h5>
                                                        </CCol>
                                                        <CCol xs="3" sm="3" md="1">
                                                            <FontAwesomeIcon icon={faEdit} className="fa-md" />
                                                        </CCol>
                                                    </CRow>
                                                </CCardHeader>
                                                <CCardBody>
                                                    <CListGroup>
                                                        <CListGroupItem>
                                                            <CRow>
                                                                <CCol xs="3" sm="3" md="3">
                                                                    Page Id
                                                            </CCol>
                                                                <CCol xs="9" sm="9" md="9">
                                                                    {smApplications[key].SocialMediaAccounts[accKey].PageId}
                                                                </CCol>
                                                            </CRow>
                                                        </CListGroupItem>
                                                        <CListGroupItem>
                                                            <CRow>
                                                                <CCol xs="3" sm="3" md="3">
                                                                    Callback URL
                                                            </CCol>
                                                                <CCol xs="9" sm="9" md="9">
                                                                    {smApplications[key].SocialMediaAccounts[accKey].CallbackUrl}
                                                                </CCol>
                                                            </CRow>
                                                        </CListGroupItem>
                                                    </CListGroup>

                                                </CCardBody>

                                            </CCard>
                                         */}
                                            </CCol>

                                        )

                                    })
                                }

                            </CRow>

                        </CListGroup>

                    </CCardBody>
                </CCollapse>
            </CCard>
        )
    })

    return (
        <>
            <ApplicationModal ref={childRef} onSave={onSaveApplication} onUpdate={onUpdateApplication} />
            <AccountModal ref={childRefAcc} onSave={onSaveAccount} onUpdate={onUpdateAccount} />
            <CRow>
                <CCol xs="12" sm="12" md="12">
                    <CCard>
                        <CCardHeader className='sm-card-header'>
                            Social App Manager
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol xs="0" sm="8" md="9">

                                </CCol>
                                <CCol xs="12" sm="4" md="3">

                                    <CButton className="btn btn-primary mr-1 mb-1 sm-float-right" onClick={() => {
                                        childRef.current.openModal();
                                    }}><FontAwesomeIcon icon={faPlusSquare} className="fa-md" /> New Application</CButton>

                                </CCol>
                            </CRow>
                            <br />
                            {
                                isDataLoading && <CRow>
                                    <CCol xs="12" sm="12" md="12">
                                        <CCard>
                                            <CCardBody className="sm-text-align-center">
                                                Loading Data, Please Wait.....
                                            </CCardBody>
                                        </CCard>
                                    </CCol>
                                </CRow>
                            }
                            {!isDataLoading && appCount === 0 && <CRow>
                                <CCol xs="12" sm="12" md="12">
                                    <CCard>
                                        <CCardBody className="sm-text-align-center">
                                            No applications found in app manager - You may add new applications by pressing the New Application button
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>}
                            {!isDataLoading && appCount > 0 && appListElement}
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

        </>


    )
};

export default SocialAppManager;
