//API ENDPOINTS
export const managementApiUrl = window.env.REACT_APP_SMADMIN_MANAGEMENTAPI_URL || 'http://localhost:18082';
export const callbackApiUrl = window.env.REACT_APP_SMADMIN_CALLBACK_URL || 'https://callback.qa2.tetherfi.cloud';
export const mediaUploaderUrl = window.env.REACT_APP_SMADMIN_MEDIA_UPLOADER_URL || 'https://social-chat-image-storage-001-rppftw5b3q-an.a.run.app';
export const videoThumbnailUrl = window.env.REACT_APP_SMADMIN_VIDEO_THUMBNAIL_URL || 'http://localhost:3019';
export const audioThumbnailUrl = window.env.REACT_APP_SMADMIN_AUDIO_THUMBNAIL_URL || 'http://localhost:3019';
export const dashboardUrl = window.env.REACT_APP_SMADMIN_DASHBOARD_URL || 'http://18.118.10.224:8080';

//OTHER CONFIGS
export const contactUploadBatchSize = window.env.REACT_APP_SMADMIN_UPLOAD_BATCH_SIZE || 50;
export const contactUploadInterval = window.env.REACT_APP_SMADMIN_UPLOAD_INTERVAL || 1000; //in milliseconds
export const csvContactsPerPage = window.env.REACT_APP_SMADMIN_CSV_CONTACTS_PER_PAGE || 5; //in milliseconds
export const subFolderPath = window.env.REACT_APP_SMADMIN_SUBFOLDER_PATH || '/smadmin';
export const mediaEndpointType = window.env.REACT_APP_SMADMIN_MEDIA_ENDPOINT_TYPE || 'TETHERFI'; //ESL, TETHERFI
export const useProxyForUpload = window.env.REACT_APP_SMADMIN_USE_PROXY_UPLOAD || 'NO'; //YES, NO
export const useLogo = window.env.REACT_APP_SMADMIN_USE_LOGO || 'YES'; //YES, NO