import { managementApiUrl } from '../config/config';
const createApplicationModel = (data = []) => {

    return data.reduce((acc, app) => {
        acc[app.Id] = {
            Id: app.Id,
            Name: app.Name,
            SocialMediaTypeId: app.SocialMediaTypeId,
            AppId: app.AppId,
            AppSecret: app.AppSecret,
            Active: app.Active,
            updatedby: app.updatedby,
            createdby: app.createdby,
            SocialMediaAccounts: app.SocialMediaAccounts ? app.SocialMediaAccounts.reduce((acc2, account) => {
                acc2[account.Id] = {
                    Id: account.Id,
                    Name: account.Name,
                    SocialMediaApplicationId: account.SocialMediaApplicationId,
                    CallbackUrl: account.CallbackUrl,
                    CallbackToken: account.CallbackToken,
                    AccessToken: account.AccessToken,
                    PageId: account.PageId,
                    Active: account.Active,
                    updatedby: account.updatedby,
                    createdby: account.createdby
                }
                return acc2;

            }, {}) : {}
        };

        return acc;

    }, {});

}

export class ManagementAPI {

    static getSocialAccounts = () => {
        return new Promise((resolve, reject) => {

            let url = `${managementApiUrl}/Manage/Social/Accounts`;
            console.debug(`[managementApi.getSocialAccounts] - Calling API : ${url}`);

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.isSuccess) {
                    console.debug(`[managementApi.getSocialAccounts] - Success - API RESPONSE : ${JSON.stringify(data)}`)
                    resolve(data.result);
                }
                else {
                    console.error(`[managementApi.getSocialAccounts] - Error loading social media accounts - API RESPONSE : ${JSON.stringify(data)}`);
                    resolve([]);
                }
            }).catch(ex => {

                reject(ex);
                console.error('[managementApi.getSocialAccounts]- Error loading social media accounts', ex);

            });

        });

    }

    static saveSocialApplication = (app) => {
        return new Promise((resolve, reject) => {

            app.Workspace = 'tetherfisl';
            app.createdby = 'SMAdmin';

            let url = `${managementApiUrl}/Manage/Social/Application`;
            console.debug(`[managementApi.saveSocialApplication] - Calling API : ${url}`);

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(app)
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.isSuccess) {
                    console.debug(`[managementApi.saveSocialApplication] - Success - API RESPONSE : ${JSON.stringify(data)}`);
                    app.Id = data.result;
                    app.SocialMediaAccounts = {};
                    resolve(app);
                }
                else {
                    console.error(`[managementApi.saveSocialApplication] - Error saving social media applications - API RESPONSE : ${JSON.stringify(data)}`);
                    reject(new Error('Error saving social media applications'));
                }
            }).catch(ex => {

                reject(ex);
                console.error('[managementApi.saveSocialApplications]- Error saving social media applications', ex);

            });

        });

    }

    static updateSocialApplication = (app) => {
        return new Promise((resolve, reject) => {
            app.createdby = 'SMAdmin';

            let url = `${managementApiUrl}/Manage/Social/Application/${app.Id}`;
            console.debug(`[managementApi.updateSocialApplication] - Calling API : ${url}`);

            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(app)
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.isSuccess) {
                    console.debug(`[managementApi.updateSocialApplication] - Success - API RESPONSE : ${JSON.stringify(data)}`);
                    resolve(app);
                }
                else {
                    console.error(`[managementApi.updateSocialApplication] - Error saving social media applications - API RESPONSE : ${JSON.stringify(data)}`);
                    reject(new Error('Error updating social media applications'));
                }
            }).catch(ex => {

                reject(ex);
                console.error('[managementApi.updateSocialApplication]- Error saving social media applications', ex);


            });

        });

    }

    static saveSocialAccount = (appId, acc) => {
        return new Promise((resolve, reject) => {

            acc.Workspace = 'tetherfisl';
            acc.createdby = 'SMAdmin';
            acc.SocialMediaApplicationId = appId;

            let url = `${managementApiUrl}/Manage/Social/Account`;
            console.debug(`[managementApi.saveSocialAccount] - Calling API : ${url}`);

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(acc)
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.isSuccess && data.result) {
                    acc.Id = data.result;
                    console.debug(`[managementApi.saveSocialAccount] - Success - API RESPONSE : ${JSON.stringify(data)}`);
                    resolve(acc);
                }
                else {
                    console.error(`[managementApi.saveSocialAccount] - Error saving social media account - API RESPONSE : ${JSON.stringify(data)}`);
                    reject(new Error('Error saving social media account'));
                }
            }).catch(ex => {

                reject(ex);
                console.error('[managementApi.saveSocialAccount]- Error saving social media account', ex);

            });

        });

    }

    static updateSocialAccount = (acc) => {
        return new Promise((resolve, reject) => {
            acc.createdby = 'SMAdmin';

            let url = `${managementApiUrl}/Manage/Social/Account/${acc.Id}`;
            console.debug(`[managementApi.updateSocialAccount] - Calling API : ${url}`);

            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(acc)
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.isSuccess) {
                    console.debug(`[managementApi.updateSocialAccount] - Success - API RESPONSE : ${JSON.stringify(data)}`);
                    resolve(acc);
                }
                else {
                    console.error(`[managementApi.updateSocialAccount] - Error saving social media account - API RESPONSE : ${JSON.stringify(data)}`);
                    reject(new Error('Error updating social media account'));
                }
            }).catch(ex => {

                reject(ex);
                console.error('[managementApi.updateSocialAccount]- Error saving social media account', ex);
            });

        });

    }

    static getSocialApplications = () => {
        return new Promise((resolve, reject) => {

            let url = `${managementApiUrl}/Manage/Social/Applications`;
            console.debug(`[managementApi.getSocialApplications] - Calling API : ${url}`);

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.isSuccess) {
                    console.debug(`[managementApi.getSocialApplications] - Success - API RESPONSE : ${JSON.stringify(data)}`);
                    let appResult = createApplicationModel(data.result);
                    resolve(appResult);
                }
                else {
                    console.error(`[managementApi.getSocialApplications] - Error loading social media applications - API RESPONSE : ${JSON.stringify(data)}`);
                    reject(new Error('Error loading social media applications'));
                }
            }).catch(ex => {

                reject(ex);
                console.error('[managementApi.getSocialApplications]- Error loading social media applications', ex);

            });

        });

    }

    static uploadDialerContats = (uploadData) => {

        return new Promise((resolve, reject) => {

            let url = `${managementApiUrl}/Manage/POST/OutboundSMDialing/ContactUpload`;

            console.debug(`[managementApi.uploadDialerContats] - Calling API : ${url}`);

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(uploadData)
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data && data.isSuccess) {
                    console.debug(`[managementApi.uploadDialerContats] - Success - API RESPONSE : ${JSON.stringify(data)}`)
                    resolve(true);

                }
                else {
                    console.error(`[managementApi.uploadDialerContats] - Error uploading dialer contacts - API RESPONSE : ${JSON.stringify(data)}`);
                    resolve(false);
                }
            }).catch(ex => {
                console.error('[managementApi.uploadDialerContats]- Error uploading dialer contacts', ex);
                reject(ex);

            });

        });
    };

}
