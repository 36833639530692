export const imageTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/png"];
export const documentTypes = [/* "document/pdf", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document" */];
export const videoTypes = [
    /* "video/mp4",
    "video/3gp",
    "video/webm",
    "video/avi",
    "video/webm;codecs=vp9",
    "video/webm;codecs=h264" */
];
export const audioTypes = [/* "audio/mp3", "audio/webm", "audio/webm;codecs=opus", "audio/mpeg" */];


//bmp - fb not suported

export const supportedTypes = {
    "ALL": {
        ...imageTypes.reduce((acc, cur) => {
            acc[cur] = "image";
            return acc;
        }, {}), ...videoTypes.reduce((acc, cur) => {
            acc[cur] = "video";
            return acc;
        }, {}), ...audioTypes.reduce((acc, cur) => {
            acc[cur] = "audio";
            return acc;
        }, {})
    },
    "FB": {
        "image/jpg": "image",
        "image/jpeg": "image",
        "image/png": "image",
        ...videoTypes.reduce((acc, cur) => {
            acc[cur] = "video";
            return acc;
        }, {}), ...audioTypes.reduce((acc, cur) => {
            acc[cur] = "audio";
            return acc;
        }, {})
    },
    "LINE": {
        ...imageTypes.reduce((acc, cur) => {
            acc[cur] = "image";
            return acc;
        }, {}), ...videoTypes.reduce((acc, cur) => {
            acc[cur] = "video";
            return acc;
        }, {}), ...audioTypes.reduce((acc, cur) => {
            acc[cur] = "audio";
            return acc;
        }, {})
    }
}