import React, { forwardRef, useState, useImperativeHandle } from 'react';
import {
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton,
    CRow,
    CCol,
    CFormGroup,
    CInput,
    CLabel,
    CSwitch,
    CBadge
} from '@coreui/react';
import PasswordInput from '../../reusables/password/passwordInput';
import { ManagementAPI } from '../../../services/managementApi';
import { showAlert } from '../../../util/uiNotifications';

const AccountModal = forwardRef((props, ref) => {

    const [modalState, setModalState] = useState(false);
    const [appData, setAppData] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const [appId, setAppId] = useState(null);
    const [appChannel, setAppChannel] = useState('');

    const closeModal = () => {
        setModalState(false);
        setAppData({});
        setIsUpdate(false);
        setAppId(null);
        setAppChannel('');
    }

    const saveAccount = () => {
        ManagementAPI.saveSocialAccount(appId, appData).then(acc => {
            props.onSave(acc);
            showAlert('success', 'Success', `Account saved successfully`);
            closeModal();
        }).catch(ex => {
            showAlert('error', 'Save Error', `Error occured while saving account - please try again`);
            console.error(ex);
        })

    }

    const updateAccount = () => {
        ManagementAPI.updateSocialAccount(appData).then(acc => {
            props.onUpdate(acc);
            showAlert('success', 'Success', `Application updated successfully`);
            closeModal();
        }).catch(ex => {
            showAlert('error', 'Update Error', `Error occured while updating application - please try again`);
            console.error(ex);
        })

    }

    const onFormInputChanged = (name, value) => {
        setAppData(prevVal => { return { ...prevVal, [name]: value } })
    }

    useImperativeHandle(ref, () => ({

        openModal(appId, data, channel) {
            setModalState(true);
            setAppId(appId);
            setAppChannel(channel);
            data && setIsUpdate(true);
            data && setAppData(data);
            !data && setAppData({ Active: true })
            //populate based on data
        }

    }));

    const isFormValid = () => {
        return appData.Name && (appChannel !== 'VIBER' || appData.CallbackUrl) && appData.CallbackToken && appData.AccessToken && appData.PageId;
    }

    const getStatusBadge = (status) => {

        switch (status) {
            case true: {
                return (<CBadge className='sm-app-mgr-badge-margin-top' color='success'>
                    Active
                </CBadge>);
            }
            case false: {
                return (<CBadge className='sm-app-mgr-badge-margin-top' color='danger'>
                    Inactive
                </CBadge>);
            }
            default: {
                return (<CBadge className='sm-app-mgr-badge-margin-top' color='success'>
                    Active
                </CBadge>);
            }
        }


    }

    return (

        modalState && <CModal
            show={modalState}
            onClose={closeModal}
            centered={true}
            closeOnBackdrop={false}
        >
            <CModalHeader className="sm-modal-header" closeButton>{`${isUpdate ? 'Update Account' : 'Add New Account'}`}</CModalHeader>
            <CModalBody>
                <CRow>
                    <CCol xs="12" sm="12" md="12">
                        <CFormGroup>
                            <CLabel htmlFor="acc_modal_name">Name</CLabel>
                            <CInput id="acc_modal_name" name="acc_modal_name" value={appData.Name} onChange={(event) => {
                                onFormInputChanged("Name", event.target.value);
                            }} />
                        </CFormGroup>
                    </CCol>
                    {appChannel === 'VIBER' && <CCol xs="12" sm="12" md="12">
                        <CFormGroup>
                            <CLabel htmlFor="acc_modal_cburl">Callback Url</CLabel>
                            <CInput id="acc_modal_cburl" name="acc_modal_cburl" value={appData.CallbackUrl} onChange={(event) => {
                                onFormInputChanged("CallbackUrl", event.target.value);
                            }} />
                        </CFormGroup>
                    </CCol>}
                    <CCol xs="12" sm="12" md="12">
                        <CFormGroup>
                            <CLabel htmlFor="acc_modal_cbtoken">Callback Token</CLabel>
                            <PasswordInput id="acc_modal_cbtoken" name="acc_modal_cbtoken" inputVal={appData.CallbackToken} onChange={(event) => {
                                onFormInputChanged("CallbackToken", event.target.value);
                            }} />
                        </CFormGroup>

                    </CCol>

                    <CCol xs="12" sm="12" md="12">

                        <CFormGroup>

                            <CLabel htmlFor="acc_modal_accesstoken">Access Token</CLabel>
                            <PasswordInput id="acc_modal_accesstoken" name="acc_modal_accesstoken" inputVal={appData.AccessToken} onChange={(event) => {
                                onFormInputChanged("AccessToken", event.target.value);
                            }} />

                        </CFormGroup>

                    </CCol>

                    <CCol xs="12" sm="12" md="12">
                        <CFormGroup>
                            <CLabel htmlFor="acc_modal_pageid">Page Id</CLabel>
                            <CInput id="acc_modal_pageid" name="acc_modal_pageid" value={appData.PageId} onChange={(event) => {
                                onFormInputChanged("PageId", event.target.value);
                            }} />
                        </CFormGroup>
                    </CCol>

                </CRow>
                <CRow>
                    <CCol xs="2" sm="2" md="2">
                        <CSwitch className={'mx-1 sm-app-mgr-switch'} shape={'pill'} color={'success'} labelOn={'\u2713'} labelOff={'\u2715'} checked={appData.Active} onChange={(event) => {
                            onFormInputChanged("Active", event.target.checked);
                        }} />
                    </CCol>
                    <CCol xs="4" sm="4" md="4">
                        {getStatusBadge(appData.Active)}
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                {
                    isUpdate ? (<CButton className={`${isFormValid() ? 'sm-cursor-pointer' : 'sm-cursor-na'}`} disabled={!isFormValid()}
                        color="primary"
                        onClick={updateAccount}
                    >Update</CButton>) : (<CButton className={`${isFormValid() ? 'sm-cursor-pointer' : 'sm-cursor-na'}`} disabled={!isFormValid()}
                        color="primary"
                        onClick={saveAccount}
                    >Save</CButton>)
                }

            </CModalFooter>
        </CModal >

    )
});

export default AccountModal;


