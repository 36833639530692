import React from 'react';
import { CWidgetProgressIcon, CCol, CRow } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CountWidget = (props) => {


    return (

        <CWidgetProgressIcon
            header={<CRow>
                <CCol sm="6">
                    {props.valueLeft}
                </CCol>
                <CCol sm="6">
                    {props.valueRight}
                </CCol>
            </CRow>}
            text={<CRow>
                <CCol sm="6">
                    {props.textLeft}
                </CCol>
                <CCol sm="6">
                    {props.textRight}
                </CCol>
            </CRow>}
            color={props.color}
            inverse
        >
            <CRow>
                <CCol sm="6">
                    <div style={{ fontSize: "17px", textAlign: "left", marginTop: "0.5rem" }}>{props.heading}</div>
                </CCol>
                <CCol sm="6">
                    <FontAwesomeIcon icon={props.icon} className="fa-lg" />
                </CCol>
            </CRow>


        </CWidgetProgressIcon>
    )
};

export default CountWidget;
