import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuItem from "./menuItem";
import * as panelActions from '../../../redux/actions/panelActions';
import * as selectedPageActions from '../../../redux/actions/selectedPageActions';

const MenuList = (props) => {

    const [openState, setOpenState] = useState(false);
    const [forceCloseState, setForceCloseState] = useState(false);

    let menuItems = props.menuItems.map(item => {
        return (
            <MenuItem id={item.id} key={item.id} displayName={item.displayName} icon={item.icon} link={item.link} onClick={() => {
                props.panel_actions.navigationMobileHide();
                props.selected_page_actions.pageSelected(item.id, props.id);
            }} />

        )
    })


    return (

        <li key={props.id} className={`c-sidebar-nav-dropdown ${(forceCloseState === false && (openState || props.id === props.parent_nav)) ? 'c-show' : ''}`}>
            <div className="c-sidebar-nav-dropdown-toggle" onClick={() => {
                setOpenState((prevState) => {
                    if (prevState) {
                        setForceCloseState(true);
                    } else {
                        setForceCloseState(false);
                    }
                    return !prevState;
                })
            }}>
                {props.icon}
                <div className="sm-menu-icon">{props.displayName}</div>
            </div>
            <ul className="c-sidebar-nav-dropdown-items">
                {menuItems}
                {/* <MenuItem id='nav_outbound-dialer_search-user' displayName='Search' icon={<FontAwesomeIcon icon={faSearch} className="fa-lg" />} link='/outbound-dialer/search-user' onClick={() => {
                    props.panel_actions.navigationMobileHide();
                    props.selected_page_actions.pageSelected('nav_outbound-dialer_search-user', props.id);
                }} />
                <MenuItem id='nav_outbound-dialer_csv-import' displayName='CSV Import' icon={<FontAwesomeIcon icon={faFileCsv} className="fa-lg" />} link='/outbound-dialer/csv-import' onClick={() => {
                    props.panel_actions.navigationMobileHide();
                    props.selected_page_actions.pageSelected('nav_outbound-dialer_csv-import', props.id);
                }} /> */}
            </ul>
        </li>

    )
};

function mapStateToProps(state) {
    return {
        parent_nav: state.selected_page.parentNav
    };
}

function mapDispatchToProps(dispatch) {
    return {
        panel_actions: bindActionCreators(panelActions, dispatch),
        selected_page_actions: bindActionCreators(selectedPageActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuList);
