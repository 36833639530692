import { dashboardUrl } from '../config/config';

export class DashboardAPI {

    static getDashboardData = () => {

        return new Promise((resolve, reject) => {

            let url = `${dashboardUrl}/Dashboard/Counts`;

            console.debug(`[dashboardApi.getDashboardData] - Calling API : ${url}`);

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                return res.json();

            }).then(data => {
                if (data) {
                    console.debug(`[dashboardApi.getDashboardData] - Success - API RESPONSE : ${JSON.stringify(data)}`)
                    resolve(data);

                }
                else {
                    console.error(`[dashboardApi.getDashboardData] - Error getting dashboard data - API RESPONSE : ${JSON.stringify(data)}`);
                    resolve(data);
                }
            }).catch(ex => {
                console.error('[dashboardApi.getDashboardData]- Error getting dashboard datas', ex);
                reject(ex);

            });

        });
    };
}
