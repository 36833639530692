import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { imageTypes, videoTypes, audioTypes, documentTypes } from '../../../../config/json/fileTypes';
import { showAlert } from '../../../../util/uiNotifications';

let mediaTypes = [...imageTypes, ...videoTypes, ...audioTypes, ...documentTypes];
let acceptMediaTypes = mediaTypes.join(',');

const FileBrowser = forwardRef((props, ref) => {

    const [visibility, setVisibility] = useState(true);
    const [file, setFile] = useState(null);

    useImperativeHandle(ref, () => ({
        getFile() {
            return file;
        },
        resetFile() {
            setFile(null);
            props.onChange && props.onChange(false);
        }
    }));


    const onFileSelected = async (event) => {

        if (event.target.files.length > 0) {

            let file = event.target.files[0];

            if (mediaTypes.indexOf(file.type) === -1) {
                showAlert('error', 'Error', 'Unsupported file format');
                return;
            }

            setFile(file);

            props.onChange && props.onChange(true);

            setVisibility(false);
        }


    }

    useEffect(() => {

        if (!visibility) {
            setVisibility(true);
        }

    }, [visibility]);

    return (

        visibility && <div style={{ borderWidth: "thin", borderColor: "gray", borderStyle: "double", borderRadius: "0.3rem", padding: "4px", display: "inline-flex", whiteSpace: "nowrap", overflow: "hidden" }}>
            <label htmlFor="outbound_dialer_attachment" className="btn btn-primary btn-block" style={{ marginTop: "4px", marginBottom: "4px", marginLeft: "7px", width: "74px" }}>
                Browse
                                </label>
            <input type="file"
                hidden
                multiple={false}
                id="outbound_dialer_attachment" name="outbound_dialer_attachment"
                accept={acceptMediaTypes}
                onChange={onFileSelected} />
            <div style={{ marginTop: "9px", marginLeft: "10px", marginRight: "20px" }}>
                {file && <FontAwesomeIcon icon={faTimes} title="Remove" color="red" className="fa-lg sm-uploader-icon sm-cursor-pointer" onClick={() => {
                    setFile(null);
                    props.onChange && props.onChange(false);
                }} />}&nbsp;&nbsp;&nbsp;
            <label title={file ? file.name : ""} style={{ maxWidth: "144px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{file ? file.name : 'Please select file...'}</label>

            </div>
        </div>


    )
});

export default FileBrowser;
