import { fireEvent } from './eventEmitter';

export const showAlert = (type, header, body) => {

    try {
        fireEvent('NOTIFICATIONS', { type, header, body });

    } catch (ex) {
        console.log(ex);
    }
}