import React, { forwardRef, useState, useImperativeHandle, useRef } from 'react';
import {
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton
} from '@coreui/react';
import ProgressBar from '../../../reusables/progressbar/progressbar';
import { ManagementAPI } from '../../../../services/managementApi';
import { contactUploadBatchSize, contactUploadInterval, videoThumbnailUrl, audioThumbnailUrl } from '../../../../config/config';
import { uploadFile } from '../../../../services/fileApi';

const ContactUploader = forwardRef((props, ref) => {

    const childRef = useRef();

    const [modalStats, setModalStats] = useState({ state: false, itemCount: 0 });
    const [uploadStatus, setUploadStatus] = useState("CONTACT_UPLOADING");
    const [isUploadError, setIsUploadError] = useState(false);

    const closeModal = () => {
        setModalStats({ state: false, itemCount: 0 });
        setUploadStatus("CONTACT_UPLOADING");
        setIsUploadError(false);
        props.onClose();
    }

    const getThumbUrl = (type) => {
        switch (type) {
            case 'audio': {
                return audioThumbnailUrl;
            }
            case 'video': {
                return videoThumbnailUrl;
            }
            default:
                return '';
        }
    }

    const upload = (uploadData) => {
        uploadStatus !== 'CONTACT_UPLOADING' && setUploadStatus("CONTACT_UPLOADING");
        let tempData = uploadData;

        let intervalId = setInterval(() => {

            let batch = tempData.splice(0, contactUploadBatchSize);
            let batchCount = batch.length;

            if (batchCount > 0) {
                ManagementAPI.uploadDialerContats(batch)
                    .then(uploadResult => {
                        if (uploadResult) {
                            childRef && childRef.current && childRef.current.contactBatchUploaded(batchCount);
                        } else {
                            console.error('Error on contact uploading - clearing interval uploader');
                            clearInterval(intervalId);
                            setIsUploadError(true);
                            childRef && childRef.current && childRef.current.haltProcessOnError();
                        }

                    })
                    .catch(ex => {
                        console.error('Error on contact uploading - clearing interval uploader', ex);
                        clearInterval(intervalId);
                        setIsUploadError(true);
                        childRef && childRef.current && childRef.current.haltProcessOnError();
                    })

            } else {
                clearInterval(intervalId);
            }

        }, contactUploadInterval);


    }

    useImperativeHandle(ref, () => ({

        startUpload(uploadData, attachment) {

            //upload file
            let itemCount = uploadData.length;
            setModalStats({ state: true, itemCount });

            if (attachment.file) {

                setUploadStatus("FILE_UPLOADING");

                uploadFile(attachment.file, attachment.fileId).then(result => {

                    let uploadDataModified = uploadData.map(data => {

                        let prevUrl = getThumbUrl(data.MessageType);
                        /* data.Message = result.streamUrl; */
                        data.OtherData = prevUrl ? JSON.stringify({ PreviewUrl: prevUrl, MediaUrl: result.streamUrl }) : JSON.stringify({ MediaUrl: result.streamUrl });
                        return data;
                    })

                    upload(uploadDataModified);

                }).catch(ex => {
                    setIsUploadError(true);
                    console.error(ex);

                });

            } else {
                upload(uploadData);
            }

            //call api and call child ref
        }

    }));

    let getUploadMessage = () => {
        if (uploadStatus === 'FILE_UPLOADING') {
            return 'Uploading media file - Please wait';
        } else {
            return 'Uploading Contacts';
        }
    }

    return (

        modalStats.state && <CModal
            show={modalStats.state}
            onClose={closeModal}
            centered={true}
            closeOnBackdrop={false}
        >
            <CModalHeader className={`${isUploadError ? 'sm-modal-header-error' : 'sm-modal-header'}`} closeButton>{`${isUploadError ? 'Error occurred on upload' : getUploadMessage()}`}</CModalHeader>
            <CModalBody>
                <ProgressBar totalCount={modalStats.itemCount} ref={childRef} onProgressCompleted={() => {
                    setUploadStatus("COMPLETED");
                }} />
            </CModalBody>
            <CModalFooter>
                {
                    uploadStatus === "COMPLETED" ? (<CButton
                        color="primary"
                        onClick={closeModal}
                    >Done</CButton>) : (<CButton
                        color="secondary"
                        onClick={closeModal}
                    >Cancel</CButton>)
                }

            </CModalFooter>
        </CModal>

    )
});

export default ContactUploader;


