import React, { useRef, useState, useEffect } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CFormGroup,
    CLabel,
    CSelect,
    CButton,
    CTextarea
} from '@coreui/react';
import Typeahead from "../../../reusables/typeahead/typeahead";
import SenderAccSelector from '../../../subcomponents/outboundDialer/senderAccSelector';
import { ManagementAPI } from '../../../../services/managementApi';
import { showAlert } from '../../../../util/uiNotifications';

const OutboundDialerSearch = (props) => {

    const childRef = useRef();

    const [formData, setFormData] = useState({});
    const [socialAccounts, setSocialAccounts] = useState([]);

    useEffect(() => {

        ManagementAPI.getSocialAccounts().then(accounts => {
            setSocialAccounts(accounts);
        }).catch(ex => {
            console.error(ex);
            showAlert('error', 'Error', 'Error loading social media accounts');
        })

    }, []);

    const onFormInputChanged = (name, value) => {
        setFormData(prevVal => { return { ...prevVal, [name]: value } })
    }

    const onUserSearch = (searchText) => {

        //call api in future
        setTimeout(() => {
            let tempArr = [{ Email: 'johndoe@gmail.com', Id: '1', Name: 'John Doe' }, { Email: 'charlie@gmail.com', Id: '2', Name: 'Charlie Blake' }]
            childRef.current.setDropdownData(tempArr);
        }, 1000);

    }

    const onItemSelected = (item) => {
        console.log(`Item selected : ${item.Name}`)
    }

    return (

        <CRow>
            <CCol xs="12" sm="12" md="12">
                <CCard>
                    <CCardHeader className='sm-card-header'>
                        Search User
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol xs="12" sm="4" md="3">
                                <CFormGroup>
                                    <CLabel htmlFor="out_dialer_search_by">Search By</CLabel>
                                    <CSelect custom name="out_dialer_search_by" id="out_dialer_search_by">
                                        <option value="Email">Email</option>
                                        <option value="FullName">Full Name</option>
                                        <option value="ProfileId">Profile Id</option>
                                    </CSelect>
                                </CFormGroup>
                            </CCol>
                            <CCol xs="12" sm="4" md="6">
                                <CFormGroup>
                                    <CLabel htmlFor="out_dialer_filter">Filter</CLabel>
                                    <Typeahead id='out_dialer_filter'
                                        placeholder='Start typing to filter users'
                                        required={true}
                                        onValueChanged={onUserSearch}
                                        onItemSelected={onItemSelected}
                                        dropDownDisplayKey='Name'
                                        minLength={3}
                                        ref={childRef} />
                                </CFormGroup>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xs="12" sm="12" md="12">
                                <CFormGroup>
                                    <CLabel htmlFor="out_dialer_search_message">Message</CLabel>
                                    <CTextarea
                                        name="textarea-input"
                                        id="textarea-input"
                                        rows="9"
                                        placeholder="Type your message..."
                                    />
                                </CFormGroup>
                            </CCol>
                        </CRow>
                        <br></br>
                        <SenderAccSelector formData={formData} socialAccounts={socialAccounts} onFormInputChanged={onFormInputChanged} />
                        <br></br>
                        <CRow>
                            <CCol xs="6" sm="3" md="2" lg="2" xl="2">
                                <CButton block color="primary">Send</CButton>
                            </CCol>

                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>

    )
};

export default OutboundDialerSearch;
