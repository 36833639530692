import React, { forwardRef, useState, useImperativeHandle } from 'react';
import {
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton,
    CRow
} from '@coreui/react';
import produce from "immer";
import SenderAccSelector from '../../../subcomponents/outboundDialer/senderAccSelector';

const SenderAccountsPicker = forwardRef((props, ref) => {

    const [modalState, setModalState] = useState(false);
    const [formData, setFormData] = useState({});
    const [fileInfo, setFileInfo] = useState({});
    const [socialAccounts, setSocialAccounts] = useState([]);
    const [uploadData, setUploadData] = useState([]);

    const closeModal = () => {
        setModalState(false);
        props.onClose();
    }

    const onUpload = () => {
        setModalState(false);
        props.onSenderAccountsSelected(uploadData, formData, fileInfo);
    }

    useImperativeHandle(ref, () => ({

        showPopup(socialAccounts, detectedSMTypes, formatted, attachmentInfo) {
            let data = detectedSMTypes.reduce((acc, smType) => {
                let firstAcc = socialAccounts.find(acc => {
                    if (acc.SocialMediaApplication && acc.SocialMediaApplication.SocialMediaTypeId === smType) {
                        return acc;
                    }
                });
                acc[smType] = { SenderAccount: firstAcc, Tag: smType === 'FB' ? 'CONFIRMED_EVENT_UPDATE' : "" }
                return acc;
            }, {});
            setFileInfo(attachmentInfo)
            setUploadData(formatted);
            setSocialAccounts(socialAccounts);
            setFormData(data);
            setModalState(true);

            //call api and call child ref
        }

    }));

    const selectorsElement = Object.keys(formData).map(key => {
        let filteredAccounts = socialAccounts.filter(acc => {
            if (acc.SocialMediaApplication && acc.SocialMediaApplication.SocialMediaTypeId === key) {
                return acc;
            }
        });

        return (<SenderAccSelector labelName={key} formData={formData[key]} socialAccounts={filteredAccounts} onFormInputChanged={(name, value) => {

            setFormData(produce(draft => {
                draft[key] = { ...draft[key], [name]: value };
                return draft;
            }));

        }} />)
    })

    return (

        modalState && <CModal
            show={modalState}
            onClose={closeModal}
            size=""
            centered={true}
            closeOnBackdrop={false}
        >
            <CModalHeader className="sm-modal-header" closeButton>Sender Account Selector</CModalHeader>
            <CModalBody>

                {selectorsElement}


            </CModalBody>
            <CModalFooter>
                <CButton
                    color="primary"
                    onClick={onUpload}
                >Upload</CButton>
                <CButton
                    color="secondary"
                    onClick={closeModal}
                >Cancel</CButton>

            </CModalFooter>
        </CModal>

    )
});

export default SenderAccountsPicker;


