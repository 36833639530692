import React from 'react';
import {
    CCol,
    CRow,
    CFormGroup,
    CLabel,
    CSelect,
    CDropdown,
    CDropdownToggle,
    CDropdownItem,
    CDropdownMenu

} from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLine, faViber, faWeixin, faTelegram, faTwitter, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle, faSms } from '@fortawesome/free-solid-svg-icons';

const SenderAccSelector = (props) => {

    const getAccountType = () => {

        return props.formData.SenderAccount && props.formData.SenderAccount.SocialMediaApplication ?
            props.formData.SenderAccount.SocialMediaApplication.SocialMediaTypeId :
            (props.socialAccounts && props.socialAccounts.length > 0 ? (props.socialAccounts[0].SocialMediaApplication ? props.socialAccounts[0].SocialMediaApplication.SocialMediaTypeId : null) : null);

    }

    const getSMIcon = (type) => {
        switch (type)
        {
            case 'FB': {
                return (<FontAwesomeIcon icon={faFacebook} className="fa-lg sm-fb" />);
            }
            case 'LINE': {
                return (<FontAwesomeIcon icon={faLine} className="fa-lg sm-line" />);
            }
            case 'WHATSAPP': {
                return (<FontAwesomeIcon icon={faWhatsapp} className="fa-lg sm-whatsapp" />);
            }
            case 'TWITTER': {
                return (<FontAwesomeIcon icon={faTwitter} className="fa-lg sm-twitter" />);
            }
            case 'VIBER': {
                return (<FontAwesomeIcon icon={faViber} className="fa-lg sm-viber" />);
            }
            case 'TELEGRAM': {
                return (<FontAwesomeIcon icon={faTelegram} className="fa-lg sm-telegram" />);
            }
            case 'WE': {
                return (<FontAwesomeIcon icon={faWeixin} className="fa-lg sm-wechat" />);
            }
            case 'TWILIO': {
                return (<FontAwesomeIcon icon={faSms} className="fa-lg sm-twilio" />);
            }
            case 'INSTAGRAM': {
                return (<FontAwesomeIcon icon={faInstagram} className="fa-lg sm-instagram" />);
            }
            default: {
                return (<FontAwesomeIcon icon={faQuestionCircle} className="fa-lg sm-unknown" />);
            }
        }

    }

    return (

        <CRow>
            <CCol xs="12" sm="6" md="6" lg="6" xl="6">
                <CFormGroup>
                    <CLabel htmlFor="out_dialer_sender_acc">{`${props.labelName} Sender Account`}</CLabel>
                    <CDropdown className="m-1">
                        <CDropdownToggle className="sm-dropdown">
                            {getSMIcon(getAccountType())}
                            &nbsp;&nbsp;&nbsp;{props.formData.SenderAccount ? props.formData.SenderAccount.Name : (props.socialAccounts && props.socialAccounts.length > 0 ? props.socialAccounts[0].Name : "")}
                        </CDropdownToggle>
                        <CDropdownMenu>
                            {props.socialAccounts.reduce((accumulator, acc) => {

                                if (acc.Active)
                                {
                                    accumulator.push(<CDropdownItem key={`out_dialer_sender_acc${acc.Id}`} onClick={() => {
                                        props.onFormInputChanged("SenderAccount", acc);
                                    }}>
                                        {getSMIcon(acc.SocialMediaApplication.SocialMediaTypeId)}
                                        &nbsp;&nbsp;&nbsp;{acc.Name}</CDropdownItem>)
                                }

                                return accumulator;

                            }, [])}

                        </CDropdownMenu>
                    </CDropdown>

                </CFormGroup>
            </CCol>

            {getAccountType() === 'FB' && <CCol xs="12" sm="6" md="6" lg="6" xl="6">
                <CFormGroup>
                    <CLabel htmlFor="out_dialer_sender_acc_tag">Tag</CLabel>
                    <CSelect className="sm-tag-select" custom name="out_dialer_sender_acc_tag" id="out_dialer_sender_acc_tag" onChange={(event) => {
                        props.onFormInputChanged("Tag", event.target.value);
                    }}>
                        <option value={`CONFIRMED_EVENT_UPDATE`}>{`CONFIRMED_EVENT_UPDATE`}</option>
                        <option value={`POST_PURCHASE_UPDATE`}>{`POST_PURCHASE_UPDATE`}</option>
                        <option value={`ACCOUNT_UPDATE`}>{`ACCOUNT_UPDATE`}</option>
                    </CSelect>
                </CFormGroup>
            </CCol>}
        </CRow>

    )
};

export default SenderAccSelector;
