import React, { useRef, useState, useEffect } from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CCardGroup
} from '@coreui/react';
import {
    CChartBar,
    CChartLine,
    CChartDoughnut,
    CChartRadar,
    CChartPie,
    CChartPolarArea
} from '@coreui/react-chartjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { faPlusSquare, faSync, faSms } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLine, faViber, faWeixin, faTelegram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { DashboardAPI } from '../../../services/dashboardApi';
import { showAlert } from '../../../util/uiNotifications';
import CountWidget from './widgets/countWidget';

const Dashboard = (props) => {

    const childRef = useRef();

    const [dashboardData, setDashboardData] = useState({});

    const getDashboardData = () => {
        DashboardAPI.getDashboardData().then(data => {

            setDashboardData(data);

        }).catch(ex => {
            showAlert('error', 'Error', `Error loading dashboard data`);
            console.error(ex);
        })
    }

    useEffect(() => {

        getDashboardData();

    }, []);


    const getWidgetCounts = (dashboardData) => {

        let returnObj = {
            all: {
                inbound: 0,
                outbound: 0,
            },
            facebook: {
                inbound: 0,
                outbound: 0
            },
            whatsapp: {
                inbound: 0,
                outbound: 0
            },
            viber: {
                inbound: 0,
                outbound: 0
            },
            line: {
                inbound: 0,
                outbound: 0
            },
            twilio: {
                inbound: 0,
                outbound: 0
            }
        };

        try {

            if (dashboardData && Object.keys(dashboardData).length > 0) {
                let lastDayData = dashboardData[Object.keys(dashboardData)[0]];

                returnObj.all.inbound = lastDayData['DIRECTION'] && lastDayData['DIRECTION']['inbound'] ? lastDayData['DIRECTION']['inbound'] : 0;
                returnObj.all.outbound = lastDayData['DIRECTION'] && lastDayData['DIRECTION']['outbound'] ? lastDayData['DIRECTION']['outbound'] : 0;
                returnObj.facebook.inbound = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['FB'] && lastDayData['CHANNEL']['FB']['DIRECTION'] && lastDayData['CHANNEL']['FB']['DIRECTION']['inbound'] ? lastDayData['CHANNEL']['FB']['DIRECTION']['inbound'] : 0;
                returnObj.facebook.outbound = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['FB'] && lastDayData['CHANNEL']['FB']['DIRECTION'] && lastDayData['CHANNEL']['FB']['DIRECTION']['outbound'] ? lastDayData['CHANNEL']['FB']['DIRECTION']['outbound'] : 0;
                returnObj.whatsapp.inbound = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['WHATSAPP'] && lastDayData['CHANNEL']['WHATSAPP']['DIRECTION'] && lastDayData['CHANNEL']['WHATSAPP']['DIRECTION']['inbound'] ? lastDayData['CHANNEL']['WHATSAPP']['DIRECTION']['inbound'] : 0;
                returnObj.whatsapp.outbound = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['WHATSAPP'] && lastDayData['CHANNEL']['WHATSAPP']['DIRECTION'] && lastDayData['CHANNEL']['WHATSAPP']['DIRECTION']['outbound'] ? lastDayData['CHANNEL']['WHATSAPP']['DIRECTION']['outbound'] : 0;
                returnObj.viber.inbound = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['VIBER'] && lastDayData['CHANNEL']['VIBER']['DIRECTION'] && lastDayData['CHANNEL']['VIBER']['DIRECTION']['inbound'] ? lastDayData['CHANNEL']['VIBER']['DIRECTION']['inbound'] : 0;
                returnObj.viber.outbound = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['VIBER'] && lastDayData['CHANNEL']['VIBER']['DIRECTION'] && lastDayData['CHANNEL']['VIBER']['DIRECTION']['outbound'] ? lastDayData['CHANNEL']['VIBER']['DIRECTION']['outbound'] : 0;
                returnObj.line.inbound = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['LINE'] && lastDayData['CHANNEL']['LINE']['DIRECTION'] && lastDayData['CHANNEL']['LINE']['DIRECTION']['inbound'] ? lastDayData['CHANNEL']['LINE']['DIRECTION']['inbound'] : 0;
                returnObj.line.outbound = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['LINE'] && lastDayData['CHANNEL']['LINE']['DIRECTION'] && lastDayData['CHANNEL']['LINE']['DIRECTION']['outbound'] ? lastDayData['CHANNEL']['LINE']['DIRECTION']['outbound'] : 0;
                returnObj.twilio.inbound = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['TWILIO'] && lastDayData['CHANNEL']['TWILIO']['DIRECTION'] && lastDayData['CHANNEL']['TWILIO']['DIRECTION']['inbound'] ? lastDayData['CHANNEL']['TWILIO']['DIRECTION']['inbound'] : 0;
                returnObj.twilio.outbound = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['TWILIO'] && lastDayData['CHANNEL']['TWILIO']['DIRECTION'] && lastDayData['CHANNEL']['TWILIO']['DIRECTION']['outbound'] ? lastDayData['CHANNEL']['TWILIO']['DIRECTION']['outbound'] : 0;



            }

            return returnObj;


        } catch (ex) {
            console.error(ex);
            return returnObj;
        }


    }

    const getLastDayCountsByChannel = (dashboardData) => {

        let returnObj = {
            facebook: { label: 'Facebook', count: 0, color: '#23A94B' },
            whatsapp: { label: 'Whatsapp', count: 0, color: '#F8A511' },
            viber: { label: 'Viber', count: 0, color: '#2819B8' },
            line: { label: 'Line', count: 0, color: '#DE4343' },
            wechat: { label: 'WeChat', count: 0, color: '#2C89DC' },
            telegram: { label: 'Telegram', count: 0, color: '#E46651' },
            twilio: { label: 'Twilio', count: 0, color: '#732B90' }
        };

        try {

            if (dashboardData && Object.keys(dashboardData).length > 0) {
                let lastDayData = dashboardData[Object.keys(dashboardData)[0]];

                returnObj.facebook.count = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['FB'] && lastDayData['CHANNEL']['FB']['total'] ? lastDayData['CHANNEL']['FB']['total'] : 0;
                returnObj.whatsapp.count = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['WHATSAPP'] && lastDayData['CHANNEL']['WHATSAPP']['total'] ? lastDayData['CHANNEL']['WHATSAPP']['total'] : 0;
                returnObj.viber.count = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['VIBER'] && lastDayData['CHANNEL']['VIBER']['total'] ? lastDayData['CHANNEL']['VIBER']['total'] : 0;
                returnObj.line.count = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['LINE'] && lastDayData['CHANNEL']['LINE']['total'] ? lastDayData['CHANNEL']['LINE']['total'] : 0;
                returnObj.wechat.count = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['WE'] && lastDayData['CHANNEL']['WE']['total'] ? lastDayData['CHANNEL']['WE']['total'] : 0;
                returnObj.telegram.count = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['TELEGRAM'] && lastDayData['CHANNEL']['TELEGRAM']['total'] ? lastDayData['TELEGRAM']['FB']['total'] : 0;
                returnObj.twilio.count = lastDayData['CHANNEL'] && lastDayData['CHANNEL']['TWILIO'] && lastDayData['CHANNEL']['TWILIO']['total'] ? lastDayData['CHANNEL']['TWILIO']['total'] : 0;


            }

            return returnObj;


        } catch (ex) {
            console.error(ex);
            return returnObj;
        }


    }

    const getLastDayCountsByType = (dashboardData) => {

        let returnObj = {
            text: { label: 'Text', count: 0, color: '#23A94B' },
            image: { label: 'Image', count: 0, color: '#F8A511' },
            audio: { label: 'Audio', count: 0, color: '#2819B8' },
            video: { label: 'Video', count: 0, color: '#DE4343' },
            file: { label: 'File', count: 0, color: '#2C89DC' }
        };

        try {

            if (dashboardData && Object.keys(dashboardData).length > 0) {
                let lastDayData = dashboardData[Object.keys(dashboardData)[0]];

                returnObj.text.count = lastDayData['MESSAGETYPE'] && lastDayData['MESSAGETYPE']['text'] ? lastDayData['MESSAGETYPE']['text'] : 0;
                returnObj.image.count = lastDayData['MESSAGETYPE'] && lastDayData['MESSAGETYPE']['image'] ? lastDayData['MESSAGETYPE']['image'] : 0;
                returnObj.audio.count = lastDayData['MESSAGETYPE'] && lastDayData['MESSAGETYPE']['audio'] ? lastDayData['MESSAGETYPE']['audio'] : 0;
                returnObj.video.count = lastDayData['MESSAGETYPE'] && lastDayData['MESSAGETYPE']['video'] ? lastDayData['MESSAGETYPE']['video'] : 0;
                returnObj.file.count = lastDayData['MESSAGETYPE'] && lastDayData['MESSAGETYPE']['file'] ? lastDayData['MESSAGETYPE']['file'] : 0;


            }

            return returnObj;


        } catch (ex) {
            console.error(ex);
            return returnObj;
        }


    }

    const getLast7DayTotal = (dashboardData) => {

        let getDefalutData = () => {
            let dates = [];
            for (let i = 0; i < 7; i++) {
                let d = new Date();
                d.setDate(d.getDate() - i);
                dates.push(`${d.getFullYear()}:${d.getMonth() + 1}:${d.getDate()}`);
            }

            return dates.reduce((acc, dateStr) => {

                acc[dateStr] = 0;

                return acc;

            }, {});
        }

        try {

            if (dashboardData && Object.keys(dashboardData).length > 0) {
                let lastDayString = Object.keys(dashboardData)[0].replaceAll(':', '-');

                let dates = [];
                for (let i = 0; i < 7; i++) {
                    let d = new Date(lastDayString);
                    d.setDate(d.getDate() - i);
                    dates.push(`${d.getFullYear()}:${d.getMonth() + 1}:${d.getDate()}`);
                }

                return dates.reduce((acc, dateStr) => {

                    acc[dateStr] = dashboardData[dateStr] && dashboardData[dateStr].total ? dashboardData[dateStr].total : 0;

                    return acc;

                }, {});

            } else {

                return getDefalutData();

            }


        } catch (ex) {
            console.error(ex);
            return getDefalutData();
        }


    }

    const getLast7DayTotalByChannel = (dashboardData) => {

        let socialMediaTypes = [{ channel: 'FB', label: 'Facebook', color: '#23A94B' }, { channel: 'WHATSAPP', label: 'Whatsapp', color: '#F8A511' },
        { channel: 'VIBER', label: 'Viber', color: '#2819B8' }, { channel: 'LINE', label: 'Line', color: '#DE4343' }, { channel: 'WE', label: 'WeChat', color: '#2C89DC' },
        { channel: 'TELEGRAM', label: 'Telegram', color: '#E46651' }, { channel: 'TWILIO', label: 'Twilio', color: '#732B90' }]

        let getDefalutData = () => {
            let dates = [];
            for (let i = 0; i < 7; i++) {
                let d = new Date();
                d.setDate(d.getDate() - i);
                dates.push(`${d.getFullYear()}:${d.getMonth() + 1}:${d.getDate()}`);
            }

            return socialMediaTypes.reduce((acc1, sm) => {
                acc1.data[sm.channel] = { label: sm.label, color: sm.color, days: {} }
                acc1.data[sm.channel].days = dates.reduce((acc, dateStr) => {

                    acc[dateStr] = 0;

                    return acc;

                }, {});

                return acc1;

            }, { dates: dates, data: {} })
        }

        try {

            if (dashboardData && Object.keys(dashboardData).length > 0) {
                let lastDayString = Object.keys(dashboardData)[0].replaceAll(':', '-');

                let dates = [];
                for (let i = 0; i < 7; i++) {
                    let d = new Date(lastDayString);
                    d.setDate(d.getDate() - i);
                    dates.push(`${d.getFullYear()}:${d.getMonth() + 1}:${d.getDate()}`);
                }

                return socialMediaTypes.reduce((acc1, sm) => {
                    acc1.data[sm.channel] = { label: sm.label, color: sm.color, days: {} }

                    acc1.data[sm.channel].days = dates.reduce((acc, dateStr) => {

                        acc[dateStr] = dashboardData[dateStr] && dashboardData[dateStr]['CHANNEL'] && dashboardData[dateStr]['CHANNEL'][sm.channel] && dashboardData[dateStr]['CHANNEL'][sm.channel].total ? dashboardData[dateStr]['CHANNEL'][sm.channel].total : 0;

                        return acc;

                    }, {});

                    return acc1;


                }, { dates: dates, data: {} })

            } else {

                return getDefalutData();

            }


        } catch (ex) {
            console.error(ex);
            return getDefalutData();
        }


    }

    let lastDayCounts = getWidgetCounts(dashboardData);
    let sevenDayBarChart = getLast7DayTotal(dashboardData);
    let pieChartData = getLastDayCountsByChannel(dashboardData);
    let lineChartData = getLast7DayTotalByChannel(dashboardData);
    let donutChartData = getLastDayCountsByType(dashboardData);


    return (
        <>
            <CRow>
                <CCol xs="12" sm="12" md="12">
                    <CCard>
                        <CCardHeader className='sm-card-header'>
                            Dashboard
                            <FontAwesomeIcon style={{ float: "right", cursor: "pointer" }} icon={faSync} className="fa-lg" onClick={() => {
                                getDashboardData();
                            }} />
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm="6" md="4" lg="4" xl="col" xxl="2">
                                    <CountWidget valueLeft={lastDayCounts.all.inbound} valueRight={lastDayCounts.all.outbound} textLeft="Inbound" textRight="Outbound" heading="All"
                                        color="gradient-info"
                                        icon={faPlusSquare}>

                                    </CountWidget>
                                </CCol>
                                <CCol sm="6" md="4" lg="4" xl="col" xxl="2">
                                    <CountWidget valueLeft={lastDayCounts.facebook.inbound} valueRight={lastDayCounts.facebook.outbound} textLeft="Inbound" textRight="Outbound" heading="Facebook"
                                        color="gradient-success"
                                        icon={faFacebook}>

                                    </CountWidget>
                                </CCol>
                                <CCol sm="6" md="4" lg="4" xl="col" xxl="2">
                                    <CountWidget valueLeft={lastDayCounts.whatsapp.inbound} valueRight={lastDayCounts.whatsapp.outbound} textLeft="Inbound" textRight="Outbound" heading="Whatsapp"
                                        color="gradient-warning"
                                        icon={faWhatsapp}>

                                    </CountWidget>
                                </CCol>
                                <CCol sm="6" md="4" lg="4" xl="col" xxl="2">
                                    <CountWidget valueLeft={lastDayCounts.viber.inbound} valueRight={lastDayCounts.viber.outbound} textLeft="Inbound" textRight="Outbound" heading="Viber"
                                        color="gradient-primary"
                                        icon={faViber}>

                                    </CountWidget>
                                </CCol>
                                <CCol sm="6" md="4" lg="4" xl="col" xxl="2">
                                    <CountWidget valueLeft={lastDayCounts.line.inbound} valueRight={lastDayCounts.line.outbound} textLeft="Inbound" textRight="Outbound" heading="Line"
                                        color="gradient-danger"
                                        icon={faLine}>

                                    </CountWidget>
                                </CCol>
                                <CCol sm="6" md="4" lg="4" xl="4" xxl="2">
                                    <CountWidget valueLeft={lastDayCounts.twilio.inbound} valueRight={lastDayCounts.twilio.outbound} textLeft="Inbound" textRight="Outbound" heading="Twilio"
                                        color="gradient-info"
                                        icon={faSms}>

                                    </CountWidget>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12" md="12" lg="12" xl="6" xxl="6">
                                    <CCard>
                                        <CCardHeader>
                                            Last 7 Days Total Messages
                                    </CCardHeader>
                                        <CCardBody>
                                            <CChartBar
                                                datasets={[
                                                    {
                                                        label: 'Total Messages',
                                                        backgroundColor: '#2E8DE5',
                                                        data: Object.keys(sevenDayBarChart).reverse().map(key => sevenDayBarChart[key])
                                                    }
                                                ]}
                                                labels={Object.keys(sevenDayBarChart).reverse().map(key => {
                                                    return moment(key.replaceAll(':', '-')).format('YYYY-MM-DD');
                                                })}
                                                options={{
                                                    tooltips: {
                                                        enabled: true
                                                    }
                                                }}
                                            />
                                        </CCardBody>
                                    </CCard>


                                </CCol>

                                <CCol sm="12" md="12" lg="12" xl="6" xxl="6">
                                    <CCard>
                                        <CCardHeader>
                                            Last Day Message Distribution By Channel
                                    </CCardHeader>
                                        <CCardBody>
                                            <CChartPie
                                                datasets={[
                                                    {
                                                        backgroundColor: Object.keys(pieChartData).map(key => pieChartData[key].color),
                                                        data: Object.keys(pieChartData).map(key => pieChartData[key].count)
                                                    }
                                                ]}
                                                labels={Object.keys(pieChartData).map(key => pieChartData[key].label)}
                                                options={{
                                                    tooltips: {
                                                        enabled: true
                                                    }
                                                }}
                                            />
                                        </CCardBody>
                                    </CCard>


                                </CCol>

                                <CCol sm="12" md="12" lg="12" xl="6" xxl="6">
                                    <CCard>
                                        <CCardHeader>
                                            Last 7 Days Total Messages By Channel
                                    </CCardHeader>
                                        <CCardBody>
                                            <CChartLine
                                                datasets={Object.keys(lineChartData.data).map(key => {
                                                    return {
                                                        label: lineChartData.data[key].label,
                                                        backgroundColor: lineChartData.data[key].color,
                                                        data: Object.keys(lineChartData.data[key].days).reverse().map(day => lineChartData.data[key].days[day])
                                                    }

                                                })}
                                                options={{
                                                    tooltips: {
                                                        enabled: true
                                                    }
                                                }}
                                                labels={lineChartData.dates.reverse().map(key => {
                                                    return moment(key.replaceAll(':', '-')).format('YYYY-MM-DD');
                                                })}
                                            />
                                        </CCardBody>
                                    </CCard>


                                </CCol>

                                <CCol sm="12" md="12" lg="12" xl="6" xxl="6">
                                    <CCard>
                                        <CCardHeader>
                                            Last Day Message Distribution By Type
                                    </CCardHeader>
                                        <CCardBody>
                                            <CChartDoughnut
                                                datasets={[
                                                    {
                                                        backgroundColor: Object.keys(donutChartData).map(key => donutChartData[key].color),
                                                        data: Object.keys(donutChartData).map(key => donutChartData[key].count)
                                                    }
                                                ]}
                                                labels={Object.keys(donutChartData).map(key => donutChartData[key].label)}
                                                options={{
                                                    tooltips: {
                                                        enabled: true
                                                    }
                                                }}
                                            />
                                        </CCardBody>
                                    </CCard>


                                </CCol>

                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

        </>


    )
};

export default Dashboard;
