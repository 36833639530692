import React, { useState, useEffect } from 'react'
import {
    CToast,
    CToastBody,
    CToastHeader,
    CToaster
} from '@coreui/react';
import uuid from "uuid/v1";
import produce from "immer";
import { subscribeToEvents } from '../../../util/eventEmitter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faThumbsUp, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const SMAlert = () => {

    const [toasts, setToasts] = useState({});

    /* const addToast = (type, header, body) => {
        let uniqueId = uuid();
        setToasts(prevVal => {
            return {
                ...prevVal,
                [uniqueId]: { position: 'top-right', autohide: 5000, closeButton: true, fade: true, type, header, body, id: uniqueId }
            }
        })
    }

    const removeToast = (id) => {
        setToasts(prevVal => {

            let { [id]: omit, ...res } = prevVal;
            return res;
        });
    } */

    const addToast = (type, header, body) => {
        let uniqueId = uuid();
        setToasts(produce(draft => {
            draft[uniqueId] = { position: 'top-right', autohide: 5000, closeButton: true, fade: true, type, header, body, id: uniqueId };
            return draft;
        }))
    }

    const removeToast = (id) => {
        setToasts(produce(draft => {
            delete draft[id];
            return draft;
        }));
    }

    useEffect(() => {

        subscribeToEvents('NOTIFICATIONS', (data) => {
            addToast(data.type, data.header, data.body);
        }, 'SMAlerts');

    }, []);


    const toasters = (() => {
        return Object.keys(toasts).reduce((toasters, toastId) => {
            toasters[toasts[toastId].position] = toasters[toasts[toastId].position] || [];
            toasters[toasts[toastId].position].push(toasts[toastId]);
            return toasters
        }, {})
    })()

    const getIcon = (type) => {
        switch (type) {
            case 'error': {
                return faExclamationCircle;
            }
            case 'success': {
                return faThumbsUp;
            }
            default:
                return faQuestionCircle;
        }

    }


    return (
        <>
            {Object.keys(toasters).map((toasterKey) => (
                <CToaster
                    position={toasterKey}
                    key={'toaster' + toasterKey}
                >
                    {
                        toasters[toasterKey].map((toast) => {
                            return (
                                <CToast className="sm-notify"
                                    key={'toast' + toast.id}
                                    show={true}
                                    autohide={toast.autohide}
                                    fade={toast.fade}
                                    onStateChange={(state) => {
                                        if (state === false) {
                                            removeToast(toast.id);
                                        }

                                    }}
                                >
                                    <CToastHeader className={`h-${toast.type}`} closeButton={toast.closeButton}>
                                        <FontAwesomeIcon icon={getIcon(toast.type)} className="fa-lg" />
                                        &nbsp;&nbsp;{toast.header}
                                    </CToastHeader>

                                    <CToastBody className={`b-${toast.type}`}>
                                        {toast.body}
                                    </CToastBody>

                                </CToast>
                            )
                        })
                    }
                </CToaster>
            ))}
        </>
    )
}

export default SMAlert
