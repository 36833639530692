import React, { forwardRef, useState, useImperativeHandle } from 'react';
import {
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton,
    CRow,
    CCol,
    CFormGroup,
    CInput,
    CLabel,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CSwitch,
    CBadge
} from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLine, faViber, faWeixin, faTelegram, faTwitter, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle, faSms } from '@fortawesome/free-solid-svg-icons';
import PasswordInput from '../../reusables/password/passwordInput';
import { ManagementAPI } from '../../../services/managementApi';
import { showAlert } from '../../../util/uiNotifications';

const ApplicationModal = forwardRef((props, ref) => {

    const [modalState, setModalState] = useState(false);
    const [appData, setAppData] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);

    const closeModal = () => {
        setModalState(false);
        setAppData({});
        setIsUpdate(false);
    }

    const saveApplication = () => {
        ManagementAPI.saveSocialApplication(appData).then(apps => {
            props.onSave(apps);
            showAlert('success', 'Success', `Application saved successfully`);
            closeModal();
        }).catch(ex => {
            showAlert('error', 'Save Error', `Error occured while saving application - please try again`);
            console.error(ex);
        })

    }

    const updateApplication = () => {
        ManagementAPI.updateSocialApplication(appData).then(app => {
            props.onUpdate(app);
            showAlert('success', 'Success', `Application updated successfully`);
            closeModal();
        }).catch(ex => {
            showAlert('error', 'Update Error', `Error occured while updating application - please try again`);
            console.error(ex);
        })

    }

    const onFormInputChanged = (name, value) => {
        setAppData(prevVal => { return { ...prevVal, [name]: value } })
    }

    useImperativeHandle(ref, () => ({

        openModal(data) {
            setModalState(true);
            data && setIsUpdate(true);
            data && setAppData(data);

            !data && setAppData({ Active: true })
            //populate based on data
        }

    }));

    const getSMTypeSelected = () => {
        switch (appData.SocialMediaTypeId)
        {
            case 'FB': {
                return (<>
                    <FontAwesomeIcon icon={faFacebook} className="fa-lg sm-app-mgr-icon-margin-right sm-fb" />
                    Facebook
                </>)
            }
            case 'LINE': {
                return (<>
                    <FontAwesomeIcon icon={faLine} className="fa-lg sm-app-mgr-icon-margin-right sm-line" />
                    Line
                </>)
            }
            case 'VIBER': {
                return (<>
                    <FontAwesomeIcon icon={faViber} className="fa-lg sm-app-mgr-icon-margin-right sm-viber" />
                    Viber
                </>)
            }
            case 'WHATSAPP': {
                return (<>
                    <FontAwesomeIcon icon={faWhatsapp} className="fa-lg sm-app-mgr-icon-margin-right sm-whatsapp" />
                    Whatsapp
                </>)
            }
            case 'TWITTER': {
                return (<>
                    <FontAwesomeIcon icon={faTwitter} className="fa-lg sm-app-mgr-icon-margin-right sm-twitter" />
                    Twitter
                </>)
            }
            case 'WE': {
                return (<>
                    <FontAwesomeIcon icon={faWeixin} className="fa-lg sm-app-mgr-icon-margin-right sm-wechat" />
                    WeChat
                </>)
            }
            case 'TELEGRAM': {
                return (<>
                    <FontAwesomeIcon icon={faTelegram} className="fa-lg sm-app-mgr-icon-margin-right sm-telegram" />
                    Telegram
                </>)
            }
            case 'TWILIO': {
                return (<>
                    <FontAwesomeIcon icon={faSms} className="fa-lg sm-app-mgr-icon-margin-right sm-twilio" />
                    Twilio
                </>)
            }
            case 'INSTAGRAM': {
                return (<>
                    <FontAwesomeIcon icon={faInstagram} className="fa-lg sm-app-mgr-icon-margin-right sm-instagram" />
                    Instagram
                </>)
            }
            default: {
                return (<>
                    <FontAwesomeIcon icon={faQuestionCircle} className="fa-lg sm-app-mgr-icon-margin-right sm-unknown" />
                    Select Type
                </>)
            }
        }
    }

    const getStatusBadge = (status) => {

        switch (status)
        {
            case true: {
                return (<CBadge className='sm-app-mgr-badge-margin-top' color='success'>
                    Active
                </CBadge>);
            }
            case false: {
                return (<CBadge className='sm-app-mgr-badge-margin-top' color='danger'>
                    Inactive
                </CBadge>);
            }
            default: {
                return (<CBadge className='sm-app-mgr-badge-margin-top' color='danger'>
                    Inactive
                </CBadge>);
            }
        }


    }

    const isFormValid = () => {
        return appData.Name && appData.AppId && appData.AppSecret && appData.SocialMediaTypeId;
    }

    return (

        modalState && <CModal
            show={modalState}
            onClose={closeModal}
            centered={true}
            closeOnBackdrop={false}
        >
            <CModalHeader className="sm-modal-header" closeButton>{`${isUpdate ? 'Update Application' : 'Add New Application'}`}</CModalHeader>
            <CModalBody>
                <CRow>
                    <CCol xs="12" sm="12" md="12">
                        <CFormGroup>
                            <CLabel htmlFor="app_modal_name">Name</CLabel>
                            <CInput id="app_modal_name" name="app_modal_name" value={appData.Name} onChange={(event) => {
                                onFormInputChanged("Name", event.target.value);
                            }} />
                        </CFormGroup>
                    </CCol>
                    <CCol xs="12" sm="12" md="12">
                        <CFormGroup>
                            <CLabel htmlFor="app_modal_id">App Id</CLabel>
                            <CInput id="app_modal_id" name="app_modal_id" value={appData.AppId} onChange={(event) => {
                                onFormInputChanged("AppId", event.target.value);
                            }} />
                        </CFormGroup>
                    </CCol>
                    <CCol xs="12" sm="12" md="12">

                        <CFormGroup>

                            <CLabel htmlFor="app_modal_secret">App Secret</CLabel>
                            <PasswordInput id="app_modal_secret" name="app_modal_secret" inputVal={appData.AppSecret} onChange={(event) => {
                                onFormInputChanged("AppSecret", event.target.value);
                            }} />
                        </CFormGroup>

                    </CCol>

                </CRow>
                <CRow>
                    <CCol xs="6" sm="6" md="6">
                        <CDropdown className="m-1">
                            <CDropdownToggle className="sm-dropdown">
                                {getSMTypeSelected()}
                            </CDropdownToggle>
                            <CDropdownMenu>
                                <CDropdownItem key={`app_modal_social_type_fb`} onClick={() => {
                                    onFormInputChanged('SocialMediaTypeId', 'FB');
                                }}>
                                    <FontAwesomeIcon icon={faFacebook} className="fa-lg sm-app-mgr-icon-margin-right sm-fb" />
                                    Facebook</CDropdownItem>
                                <CDropdownItem key={`app_modal_social_type_line`} onClick={() => {
                                    onFormInputChanged('SocialMediaTypeId', 'LINE');
                                }}>
                                    <FontAwesomeIcon icon={faLine} className="fa-lg sm-app-mgr-icon-margin-right sm-line" />
                                    Line</CDropdownItem>

                                <CDropdownItem key={`app_modal_social_type_viber`} onClick={() => {
                                    onFormInputChanged('SocialMediaTypeId', 'VIBER');
                                }}>
                                    <FontAwesomeIcon icon={faViber} className="fa-lg sm-app-mgr-icon-margin-right sm-viber" />
                                    Viber</CDropdownItem>

                                <CDropdownItem key={`app_modal_social_type_whatsapp`} onClick={() => {
                                    onFormInputChanged('SocialMediaTypeId', 'WHATSAPP');
                                }}>
                                    <FontAwesomeIcon icon={faWhatsapp} className="fa-lg sm-app-mgr-icon-margin-right sm-whatsapp" />
                                    Whatsapp</CDropdownItem>

                                <CDropdownItem key={`app_modal_social_type_twitter`} onClick={() => {
                                    onFormInputChanged('SocialMediaTypeId', 'TWITTER');
                                }}>
                                    <FontAwesomeIcon icon={faTwitter} className="fa-lg sm-app-mgr-icon-margin-right sm-twitter" />
                                    Twitter</CDropdownItem>

                                <CDropdownItem key={`app_modal_social_type_we`} onClick={() => {
                                    onFormInputChanged('SocialMediaTypeId', 'WE');
                                }}>
                                    <FontAwesomeIcon icon={faWeixin} className="fa-lg sm-app-mgr-icon-margin-right sm-wechat" />
                                    WeChat</CDropdownItem>

                                <CDropdownItem key={`app_modal_social_type_telegram`} onClick={() => {
                                    onFormInputChanged('SocialMediaTypeId', 'TELEGRAM');
                                }}>
                                    <FontAwesomeIcon icon={faTelegram} className="fa-lg sm-app-mgr-icon-margin-right sm-telegram" />
                                    Telegram</CDropdownItem>

                                <CDropdownItem key={`app_modal_social_type_telegram`} onClick={() => {
                                    onFormInputChanged('SocialMediaTypeId', 'TWILIO');
                                }}>
                                    <FontAwesomeIcon icon={faSms} className="fa-lg sm-app-mgr-icon-margin-right sm-twilio" />
                                    Twilio</CDropdownItem>
                                <CDropdownItem key={`app_modal_social_type_instagram`} onClick={() => {
                                    onFormInputChanged('SocialMediaTypeId', 'INSTAGRAM');
                                }}>
                                    <FontAwesomeIcon icon={faInstagram} className="fa-lg sm-app-mgr-icon-margin-right sm-instagram" />
                                    Instagram</CDropdownItem>

                            </CDropdownMenu>
                        </CDropdown>
                    </CCol>
                    <CCol xs="2" sm="2" md="2">
                        <CSwitch className={'mx-1 sm-app-mgr-switch'} shape={'pill'} color={'success'} labelOn={'\u2713'} labelOff={'\u2715'} checked={appData.Active ? true : false} onChange={(event) => {
                            onFormInputChanged("Active", event.target.checked);
                        }} />
                    </CCol>
                    <CCol xs="4" sm="4" md="4">
                        {getStatusBadge(appData.Active)}
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                {
                    isUpdate ? (<CButton className={`${isFormValid() ? 'sm-cursor-pointer' : 'sm-cursor-na'}`} disabled={!isFormValid()}
                        color="primary"
                        onClick={updateApplication}
                    >Update</CButton>) : (<CButton className={`${isFormValid() ? 'sm-cursor-pointer' : 'sm-cursor-na'}`} disabled={!isFormValid()}
                        color="primary"
                        onClick={saveApplication}
                    >Save</CButton>)
                }

            </CModalFooter>
        </CModal>

    )
});

export default ApplicationModal;


