import * as types from "../actions/actionTypes";
import produce from "immer";

const initialState = { nav_panel: { visibility_state_web: true, visibility_state_mobile: false } };

export const panelReducer = produce((draft = initialState, action) => {
    switch (action.type) {
        case types.CHANGE_WEB_NAV_VISIBILITY:
            {
                draft.nav_panel.visibility_state_web = !draft.nav_panel.visibility_state_web;
                draft.nav_panel.visibility_state_mobile = false;
                return draft;

            }
        case types.CHANGE_MOBILE_NAV_VISIBILITY:
            {
                draft.nav_panel.visibility_state_mobile = !draft.nav_panel.visibility_state_mobile;
                draft.nav_panel.visibility_state_web = true;
                return draft;

            }
        case types.HIDE_MOBILE_NAV:
            {
                draft.nav_panel.visibility_state_mobile = false;
                draft.nav_panel.visibility_state_web = true;
                return draft;

            }
        default:
            return draft;
    }
});