import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

const CSVFileInput = (props) => {

    const [visibility, setVisibility] = useState(true);



    const onFileSelected = (event) => {
        props.onFileSelected(event);
        setVisibility(false);
    }

    useEffect(() => {

        if (!visibility) {
            setVisibility(true);
        }

    }, [visibility]);

    return (

        visibility && <>
            <label htmlFor="outbound_dialer_csv_import" className="btn btn-primary btn-block">
                {/* <FontAwesomeIcon icon={faFileCsv} className="fa-md" /> &nbsp;&nbsp; */}Import CSV
                                </label>
            <input type="file"
                hidden
                multiple={false}
                id="outbound_dialer_csv_import" name="outbound_dialer_csv_import"
                accept=".csv, text/csv"
                onChange={onFileSelected} />
        </>


    )
};

export default CSVFileInput;
